import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';
import TestimonialsWidgetStyles from './TestimonialsWidget.module.scss';
import Testimonial from './Testimonial';
import classNames from 'classnames';

const responsive = {
  desktop: {
    breakpoint: { max: 5000, min: 1400 },
    items: 1,
    partialVisibilityGutter: 270,
    slidesToSlide: 1,
  },
  desktop2: {
    breakpoint: { max: 1399, min: 1300 },
    items: 1,
    partialVisibilityGutter: 200,
    slidesToSlide: 1,
  },
  desktop3: {
    breakpoint: { max: 1299, min: 1250 },
    items: 1,
    partialVisibilityGutter: 150,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1249, min: 10 },
    items: 1,
    partialVisibilityGutter: 0,
    slidesToSlide: 0,
  },
};

const CustomDot = ({ index, onClick, active }) => {
  return (
    <li
      className={classNames({
        [TestimonialsWidgetStyles.customDot]: true,
        [TestimonialsWidgetStyles.activeDot]: active,
      })}
      onClick={() => onClick()}
      style={{
        display: 'inline-block',
        margin: '0 8px',
        cursor: 'pointer',
        fontWeight: active ? 'bold' : 'normal',
      }}
    >
      {index + 1}
    </li>
  );
};

const TestimonialsWidget = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const testimonialsList = [
    {
      img: '/static/img/home/TESTIMONIALS/mock-user-1.jpg',
      srcSet: `
      /static/img/home/TESTIMONIALS/testimonial1.webp 480w,
      /static/img/home/TESTIMONIALS/testimonial1@2x.webp 800w,
      /static/img/home/TESTIMONIALS/testimonial1@3x.webp 1000w
    `,
      name: 'Meera Samal',
      bodyP1: ` We ordered multiple snacks and khapli atta. Rotis made with the khapli atta were great, definitely more fibrous than any supermarket atta, needing more water to knead.`,
      bodyP2: `The snacks are extraordinary, with simple ingredients and single-use oil. No emulsifiers, preservatives, or unhealthy chemicals I don't want in my family's bodies. I will definitely reorder from there all the time.`,
      location: 'South Dublin, Ireland',
    },
    {
      img: '/static/img/home/TESTIMONIALS/mock-user-2.jpg',
      srcSet: `
      /static/img/home/TESTIMONIALS/testimonial2.webp 480w,
      /static/img/home/TESTIMONIALS/testimonial2@2x.webp 800w,
      /static/img/home/TESTIMONIALS/testimonial2@3x.webp 1000w
    `,
      name: 'Pinak Sharma',
      bodyP1:
        'Reddot has elevated the quality of homely food savories! All items from snacks to pickles are a must-try. The flavors are authentic, the taste is perfect, and they use high-quality ingredients. ',
      bodyP2:
        'The packaging is excellent, with fresh imports weekly, and no preservatives. Their intention to serve kids healthy snacks reflects honesty in their service  rather than just typical business motivation. Keep up the fantastic work, Reddot.',
      location: 'Dublin, Ireland',
    },
    {
      img: '/static/img/home/TESTIMONIALS/mock-user-2.jpg',
      srcSet: `
      /static/img/home/TESTIMONIALS/testimonial3.webp 480w,
      /static/img/home/TESTIMONIALS/testimonial3@2x.webp 800w,
      /static/img/home/TESTIMONIALS/testimonial3@3x.webp 1000w
    `,
      name: 'Arun Mankan',
      bodyP1:
        'For a long time, I searched for high-quality Indian food products. Asian shops in Germany usually sell the same sweets made elsewhere that don’t taste great. Last year, I discovered Reddot Food and ordered Indian groceries and sweets from them. I was very impressed by the quality. Having them delivered directly from India is wonderful. Opening the packets, you immediately smell the freshness. They taste as they should and remind me of my childhood in India.',
      bodyP2: null,
      location: 'Bonn, Germany',
    },
  ];

  return (
    <div>
      <div className={TestimonialsWidgetStyles.TestimonialTopParentDiv}>
        <div className="TestimonialTopStripDiv">
          <img
            src="/static/img/home/TESTIMONIALS/testimonial_top_strip.svg"
            className="TestimonialTopStripImg"
          ></img>
        </div>
      </div>
      <div className={TestimonialsWidgetStyles.TestimonialHeader}>
        <div className={TestimonialsWidgetStyles.TestimonialsTitle}>
          See for yourself
        </div>
        <div className={TestimonialsWidgetStyles.TestimonialsBelowTitle}>
          what Reddot Food lovers have to say
        </div>
      </div>
      <div className={TestimonialsWidgetStyles.TestimonialBottomDiv}>
        <div className={TestimonialsWidgetStyles.TestimonialBottomStripDiv}>
          <img
            src="/static/img/home/TESTIMONIALS/testimonial_strip_bottom.svg"
            className={TestimonialsWidgetStyles.TestimonialBottomImg}
          ></img>
        </div>
      </div>
      <div className={TestimonialsWidgetStyles.TestinomialWidgetContainer}>
        <div className={TestimonialsWidgetStyles.TestimonialWidgetContent}>
          <div className={TestimonialsWidgetStyles.CarouselParentDiv}>
            <Carousel
              responsive={responsive}
              autoPlay={false}
              swipeable={false}
              draggable={false}
              showDots={true}
              customDot={<CustomDot />}
              renderDotsOutside={true}
              infinite={false}
              partialVisible={true}
              dotListClass="custom-dot-list-style"
              arrows={false}
              containerClass={TestimonialsWidgetStyles.CarouselContainer}
              beforeChange={(nextSlide, currentSlide) => {
                setActiveIndex(nextSlide);
              }}
            >
              {testimonialsList.map((testimonial, index) => (
                <div
                  key={index}
                  className={classNames(
                    TestimonialsWidgetStyles.SliderItem,
                    'slider'
                  )}
                >
                  <Testimonial
                    {...testimonial}
                    isActive={index === activeIndex}
                  />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsWidget;

/*
 * #%L
 * React Site Starter
 * %%
 * Copyright (C) 2009 - 2017 Broadleaf Commerce
 * %%
 * Broadleaf Commerce React Starter
 *
 * Written in 2017 by Broadleaf Commerce info@broadleafcommerce.com
 *
 * To the extent possible under law, the author(s) have dedicated all copyright and related and neighboring rights to this software to the public domain worldwide. This software is distributed without any warranty.
 * You should have received a copy of the CC0 Public Domain Dedication along with this software. If not, see <http://creativecommons.org/publicdomain/zero/1.0/>.
 *
 * Please Note - The scope of CC0 Public Domain Dedication extends to Broadleaf Commerce React Starter demo application alone. Linked libraries (including all Broadleaf Commerce Framework libraries) are subject to their respective licenses, including the requirements and restrictions specified therein.
 * #L%
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import CheckoutField from 'checkout/components/CheckoutField';
import countries from 'layout/util/eu-countryCodes.json';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import PhoneNumberField from '../PhoneNumberField/PhoneNumberField';

class AddressPicker extends PureComponent {
  static propTypes = {
    addresses: PropTypes.array,
    excludedFields: PropTypes.array,
    defaultFormVisible: PropTypes.bool,
    name: PropTypes.string,
    countryOptions: PropTypes.array,
  };

  static defaultProps = {
    defaultFormVisible: true,
    excludedFields: [],
    name: 'address',
    countryOptions: countries,
  };

  state = {
    formVisible: this.props.defaultFormVisible,
  };

  _toggleVisiblity = (formVisible) => this.setState({ formVisible });

  render() {
    const { addresses, excludedFields, name, countryOptions } = this.props;
    const { formVisible } = this.state;
    return (
      <div className="address-cards address-cards-primary">
        <ul>
          {!formVisible && addresses && (
            <Field
              addresses={addresses}
              component={AddressPickerField}
              formVisible={formVisible}
              toggleVisiblity={this._toggleVisiblity}
              name={name}
            />
          )}
          {!isEmpty(addresses) && formVisible && (
            <li onClick={() => this._toggleVisiblity(!formVisible)}>
              {!formVisible ? 'Use a different address' : 'Use a saved address'}
            </li>
          )}
        </ul>
        {formVisible && (
          <AddressPickerFormFields
            excludedFields={excludedFields}
            name={name}
            countryOptions={countryOptions}
          />
        )}
      </div>
    );
  }
}

const AddressPickerField = ({
  addresses,
  input,
  formVisible,
  toggleVisiblity,
}) => {
  return (
    <div>
      <span>
        {addresses.map((customerAddress) => (
          <li
            key={customerAddress.id}
            className={
              addressEquals(input.value, customerAddress) ? 'active' : ''
            }
            onClick={() => input.onChange(customerAddress)}
          >
            <div>
              <strong>{customerAddress.addressName}</strong>
              {customerAddress.default && <small>&nbsp;&nbsp;(Default)</small>}
              <br />
              {customerAddress.firstName}&nbsp;{customerAddress.lastName}
              <br />
              {customerAddress.addressLine1}
              <br /> {customerAddress.addressLine2}
              {customerAddress.addressLine2 && <br />}
              {customerAddress.city},&nbsp;
              {customerAddress.isoCountryAlpha2 != null &&
                customerAddress.isoCountryAlpha2.name}
              &nbsp;{customerAddress.postalCode}
              <br />
              {customerAddress.phonePrimary &&
                customerAddress.phonePrimary.phoneNumber}
            </div>
          </li>
        ))}
        {!isEmpty(addresses) && (
          <li
            key={'deselectAll'}
            onClick={() => {
              input.onChange(null);
              toggleVisiblity(!formVisible);
            }}
          >
            {!formVisible ? 'Use a different address' : 'Use a saved address'}
          </li>
        )}
      </span>
    </div>
  );
};

const addressEquals = (address1, address2) => {
  return isEqual(
    pick(address1, [
      'firstName',
      'lastName',
      'addressLine1',
      'addressLine2',
      'city',
      'stateProvinceRegion',
      'postalCode',
      'phonePrimary.phoneNumber',
    ]),
    pick(address2, [
      'firstName',
      'lastName',
      'addressLine1',
      'addressLine2',
      'city',
      'stateProvinceRegion',
      'postalCode',
      'phonePrimary.phoneNumber',
    ])
  );
};

const FormValidators = {
  required: (value) => (value ? undefined : 'Required'),
};

const AddressPickerFormFields = ({ excludedFields, name, countryOptions }) => (
  <div>
    <div className="row">
      <div className="col-sm-6">
        {!excludedFields.includes(`firstName`) && (
          <Field
            component={CheckoutField}
            inputGroup={false}
            label="First Name"
            name={`${name}.firstName`}
            type="text"
            validate={FormValidators.required}
          />
        )}
      </div>
      <div className="col-sm-6">
        {!excludedFields.includes(`lastName`) && (
          <Field
            component={CheckoutField}
            inputGroup={false}
            label="Last Name"
            name={`${name}.lastName`}
            type="text"
            validate={FormValidators.required}
          />
        )}
      </div>
    </div>

    <div className="row">
      <div className="col-xs-12">
        {!excludedFields.includes(`addressLine1`) && (
          <Field
            component={CheckoutField}
            inputGroup={false}
            label="Address"
            name={`${name}.addressLine1`}
            type="text"
            validate={FormValidators.required}
          />
        )}
      </div>
      <div className="col-xs-12">
        {!excludedFields.includes(`addressLine2`) && (
          <Field
            component={CheckoutField}
            inputGroup={false}
            label="Address 2"
            name={`${name}.addressLine2`}
            type="text"
          />
        )}
      </div>
    </div>

    <div className="row">
      <div className="col-xs-12 col-sm-6">
        {!excludedFields.includes(`city`) && (
          <Field
            component={CheckoutField}
            inputGroup={false}
            label="City"
            name={`${name}.city`}
            type="text"
            validate={FormValidators.required}
          />
        )}
      </div>
      <div className="col-xs-12 col-sm-6">
        {!excludedFields.includes(`isoCountryAlpha2`) && (
          <Field
            component={CheckoutField}
            inputGroup={false}
            label="Country"
            name={`${name}.isoCountryAlpha2.alpha2`}
            type="select"
            validate={FormValidators.required}
          >
            <option value=""></option>
            {countryOptions.map((option) => (
              <option key={option.abbreviation} value={option.abbreviation}>
                {option.name}
              </option>
            ))}
          </Field>
        )}
      </div>
      <div className="col-xs-12 col-sm-6">
        {!excludedFields.includes(`postalCode`) && (
          <Field
            component={CheckoutField}
            inputGroup={false}
            label="Postal Code"
            name={`${name}.postalCode`}
            type="text"
            validate={FormValidators.required}
          />
        )}
      </div>
    </div>

    <div className="row">
      <div className="col-xs-12 col-sm-6">
        {!excludedFields.includes(`phonePrimary.phoneNumber`) && (
          <Field
            component={PhoneNumberField}
            inputGroup={false}
            label="Phone"
            name={`${name}.phonePrimary.phoneNumber`}
            type=""
            validate={FormValidators.required}
            placeholder="Enter your phone"
          />
        )}
      </div>
    </div>

    <div className="row">
      <div className="col-xs-12">
        {!excludedFields.includes(`isDefault`) && (
          <Field
            component={CheckoutField}
            inputGroup={false}
            label="Default Shipping"
            name={`${name}.isDefault`}
            type="checkbox"
          />
        )}
      </div>
    </div>
  </div>
);

export default AddressPicker;

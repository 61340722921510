/*
 * #%L
 * React Site Starter
 * %%
 * Copyright (C) 2009 - 2017 Broadleaf Commerce
 * %%
 * Broadleaf Commerce React Starter
 *
 * Written in 2017 by Broadleaf Commerce info@broadleafcommerce.com
 *
 * To the extent possible under law, the author(s) have dedicated all copyright and related and neighboring rights to this software to the public domain worldwide. This software is distributed without any warranty.
 * You should have received a copy of the CC0 Public Domain Dedication along with this software. If not, see <http://creativecommons.org/publicdomain/zero/1.0/>.
 *
 * Please Note - The scope of CC0 Public Domain Dedication extends to Broadleaf Commerce React Starter demo application alone. Linked libraries (including all Broadleaf Commerce Framework libraries) are subject to their respective licenses, including the requirements and restrictions specified therein.
 * #L%
 */
import React, { PureComponent } from 'react';
import { SubmissionError } from 'redux-form';
import Accordion from 'material-kit/components/Accordion';
import PromoCodeForm from './PromoCodeForm';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import PromoCodeStyle from './PromoCodes.module.scss';
import GlobalScss from '../../../layout/style/common.scss';

const PromoCodes = ({
  addedOfferCodes = [],
  collapsed,
  onSubmitPromo,
  removePromo,
  toggle,
  error, // Primamo error iz props
}) => (
  <div>
    <div className={GlobalScss.CardPricing__title}>
      <h4>Vouchers</h4>
    </div>

    <PromoCodeForm errorMessage={error} onSubmit={onSubmitPromo} />
    {/* <!-- Display Error Message if it exists --> */}
    {/*error && (
      <div className="alert alert-danger">
        {error}
      </div>
    )*/}

    {/* <!-- Applied Promotion Codes --> */}
    {addedOfferCodes && (
      <div className={PromoCodeStyle.PromoCodes__applied}>
        {addedOfferCodes.map((promo) => (
          <div
            className={classNames(
              'label label-primary',
              PromoCodeStyle.PromoCodes__applied__code
            )}
            key={promo.offerCode} // Make sure you have a unique key for mapped items
          >
            {promo.offerCode}
            <a
              href="#removePromo"
              onClick={(e) => {
                e.preventDefault();
                removePromo(promo.offerCode);
              }}
            >
              <i className="material-icons">close</i>
            </a>
          </div>
        ))}
      </div>
    )}
  </div>
);

class PromoCodesContainer extends PureComponent {
  state = {
    error: null,
  };

  _onSubmitPromo = (form) => {
    return this.props.addPromo(form.promoCode).then((action) => {
      if (action.payload.error) {
        const { message: errorMessages } = action.payload.error.response.body;
        const error = action.payload.error.response.body.messages[0].message;
        this.setState({ error });
        if (!isEmpty(errorMessages)) {
          throw new SubmissionError({ _error: errorMessages[0].message });
        }
      } else {
        this.setState({ error: null });
      }
    });
  };

  render() {
    const { addedOfferCodes = [] } = this.props;
    const { error } = this.state;
    return (
      <Accordion collapsed={isEmpty(addedOfferCodes)}>
        <PromoCodes
          onSubmitPromo={this._onSubmitPromo}
          error={error}
          {...this.props}
        />
      </Accordion>
    );
  }
}

export default PromoCodesContainer;

/*
 * #%L
 * React Site Starter
 * %%
 * Copyright (C) 2009 - 2017 Broadleaf Commerce
 * %%
 * Broadleaf Commerce React Starter
 *
 * Written in 2017 by Broadleaf Commerce info@broadleafcommerce.com
 *
 * To the extent possible under law, the author(s) have dedicated all copyright and related and neighboring rights to this software to the public domain worldwide. This software is distributed without any warranty.
 * You should have received a copy of the CC0 Public Domain Dedication along with this software. If not, see <http://creativecommons.org/publicdomain/zero/1.0/>.
 *
 * Please Note - The scope of CC0 Public Domain Dedication extends to Broadleaf Commerce React Starter demo application alone. Linked libraries (including all Broadleaf Commerce Framework libraries) are subject to their respective licenses, including the requirements and restrictions specified therein.
 * #L%
 */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { resolve } from 'core/decorator/reduxResolve';
import { fetchCart, invalidateCart } from 'cart/actions';
import { clearPaymentData } from 'checkout/actions';
import { isAnonymous } from 'auth/selectors';
import { getCart } from 'cart/selectors';
import { CartContext } from 'cart/constants';
import SamplePaymentService from 'checkout/service/SamplePaymentService';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CheckoutStages from 'checkout/components/CheckoutStages';
import CartPricingSummary from 'cart/components/CartPricingSummary';
import CartItemSummary from 'cart/components/CartItemSummary';
import PromoCodes from 'cart/components/PromoCodes';
import Button from 'material-kit/components/Button';
import CheckoutScss from './Checkout.scss';
import GlobalScss from '../../../layout/style/common.scss';
import { addPromo } from 'cart/actions';

class Checkout extends PureComponent {
  componentDidMount() {
    // this component mounts and the stage is review, redirect to payment,
    if (this.props.location.pathname === '/checkout/review') {
      this.props.history.replace('/checkout/payment');
    }
  }

  componentWillReceiveProps(nextProps) {
    const { anonymous: wasAnonymous } = this.props;
    const { anonymous } = nextProps;
    if (wasAnonymous !== anonymous && !nextProps.isFetching) {
      nextProps.fetchCart(true);
    }
  }

  _onPerformCheckout = (paymentMethodType) => {
    const { authenticationToken, customerToken } = this.props;
    switch (paymentMethodType) {
      case SamplePaymentService.Type.CreditCard:
        const { storedPayment } = this.props;
        SamplePaymentService.tokenizeCard(storedPayment).then(
          (nonce) => {
            SamplePaymentService.performCheckout(this.props.id, nonce, {
              authenticationToken,
              customerToken,
            }).then(
              (orderNumber) => {
                this.props.invalidateCart();
                this.props.fetchCart(true);
                this.props.history.push({
                  pathname: `/confirmation/${orderNumber}`,
                });
                this.props.clearPaymentData();
              },
              (err) => {
                // handle failed checkout
                console.log(err);
              }
            );
          },
          (err) => {
            // handle failed payment submission
            console.log(err);
          }
        );

        break;
      case SamplePaymentService.Type.CollectOnDelivery:
        SamplePaymentService.performCodCheckout(this.props.id, {
          authenticationToken,
          customerToken,
        }).then(
          (orderNumber) => {
            this.props.invalidateCart();
            this.props.fetchCart(true);
            this.props.history.push({
              pathname: `/confirmation/${orderNumber}`,
            });

            this.props.clearPaymentData();
          },
          (err) => {
            // handle failed checkout
            console.log(err);
          }
        );
        break;
      case SamplePaymentService.Type.RevolutPay: {
        SamplePaymentService.performRevolutCheckout(this.props.id, {
          authenticationToken,
          customerToken,
        }).then(
          (orderNumber) => {
            this.props.invalidateCart();
            this.props.fetchCart(true);
            this.props.history.push({
              pathname: `/confirmation/${orderNumber}`,
            });
            this.props.clearPaymentData();
          },
          (err) => {
            // handle failed checkout
            console.log(err);
          }
        );
        break;
      }
      case SamplePaymentService.Type.PayPal:
        console.log('PayPal not yet supported');
        break;
      default:
        console.log('Unsupported payment method type');
    }
  };

  render() {
    const {
      addedOfferCodes = [],
      addPromo,
      anonymous,
      fulfillmentGroup,
      itemAdjustmentsValue,
      itemCount,
      orderItem,
      orderItems,
      removePromo,
      removeFromCart,
      subTotal,
      totalAdjustmentsValue,
      totalTax,
      totalShipping,
      total,
      orderAdjustmentsValue,
      totalBundleChildrenAdjustmentValue
    } = this.props;

    const hasOrderItems = itemCount > 0;

    return (
      <div styleName="CheckoutScss.Checkout">
        <Helmet titleTemplate="%s | Reddot Food Store">
          <title>Checkout</title>
          <meta name="description" content="Checkout" />
          <link rel="canonical" href="/checkout" />
        </Helmet>
        {/*<div styleName="CheckoutScss.Checkout__info_about_shipping">
          Info about shipping - Example: Free shipping on orders above €60.00
        </div>*/}
        <div className="container">
          <div className="row" styleName="CheckoutScss.Checkout_row">
            {!hasOrderItems ? (
              <div className="col-lg-12">
                <div styleName="CheckoutScss.Checkout__emptyMessage">
                  <h1>Your cart is currently empty.</h1>
                  <Button
                    component={Link}
                    componentProps={{ to: '/' }}
                    primary
                    lg
                  >
                    Continue Shopping
                  </Button>
                </div>
              </div>
            ) : (
              <div
                className="col-lg-12"
                styleName="CheckoutScss.Checkout__header_text_div"
              >
                <div styleName="GlobalScss.Text_below_header CheckoutScss.Checkout__header_text">
                  You are now on the checkout page. We need the information
                  below to process your order.
                </div>
                {
                  //<h1>Checkout</h1>
                }
              </div>
            )}

            {hasOrderItems && (
              <div
                className="col-lg-8"
                styleName="CheckoutScss.Checkout_stages"
              >
                <CheckoutStages
                  {...this.props}
                  total={total}
                  onPerformCheckout={this._onPerformCheckout}
                />
              </div>
            )}

            {hasOrderItems && (
              <div
                className="col-lg-4"
                styleName="CheckoutScss.Card_pricing_summary"
              >
                <CartPricingSummary
                  location={this.props.location}
                  anonymous={anonymous}
                  context={CartContext.Checkout}
                  fulfillmentGroup={fulfillmentGroup}
                  itemAdjustmentsValue={itemAdjustmentsValue}
                  itemCount={itemCount}
                  onPerformCheckout={this._onPerformCheckout}
                  orderItem={orderItems}
                  subTotal={subTotal}
                  totalAdjustmentsValue={totalAdjustmentsValue}
                  totalTax={totalTax}
                  totalShipping={totalShipping}
                  total={total}
                  addedOfferCodes={addedOfferCodes}
                  addPromo={addPromo}
                  removePromo={removePromo}
                  orderAdjustmentsValue={orderAdjustmentsValue}
                  totalBundleChildrenAdjustmentValue={totalBundleChildrenAdjustmentValue}
                />

                <CartItemSummary
                  context={CartContext.Checkout}
                  orderItem={orderItems}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    ...getCart(state, props),
    anonymous: isAnonymous(state, props),
    authenticationToken: state.auth.authenticationToken,
    customerToken:
      state.auth.anonymousCustomerToken || state.csr.csrCustomerToken,
    storedPayment: state.storedPayment,
  };
};

const dispatchResolve = (resolver, props) => {
  if (!props.isFetching) {
    resolver.resolve(props.fetchCart);
  }
};

export default connect(mapStateToProps, {
  clearPaymentData,
  fetchCart,
  invalidateCart,
  addPromo,
})(resolve(dispatchResolve)(Checkout));

import ReactGA from 'react-ga4';
import { GTAG, CATEGORY, ACTION, LABEL } from './consts';

const mapCartItems = (items) => {
  return items.map((item, index) => ({
    item_id: item.id,
    item_name: item.name,
    price: item.totalPrice.amount,
    quantity: item.quantity,
    item_variant: item.productOptionValue || '',
  }));
};

export const trackPageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
};

export const trackSearch = (searchTerm) => {
  ReactGA.gtag(GTAG.EVENT, ACTION.SEARCH, {
    search_term: searchTerm,
  });
};

export const trackViewItem = (props) => {
  ReactGA.gtag(GTAG.EVENT, ACTION.VIEW_ITEM, {
    currency: props.retailPrice.currency,
    value: props.retailPrice.amount,
    items: [
      {
        item_id: props.id,
        item_name: props.name,
        item_category: props.match.params.category,
        price: props.retailPrice.amount,
      },
    ],
  });
};

export const trackCartToCheckout = () => {
  ReactGA.gtag(GTAG.EVENT, ACTION.TRANSITION_CART_TO_CHECKOUT, {
    label: LABEL.CART_TO_CHECKOUT,
  });
};

export const trackCartToShop = () => {
  ReactGA.gtag(GTAG.EVENT, ACTION.TRANSITION_CART_TO_SHOP, {
    label: LABEL.CART_TO_SHOP,
  });
};

export const trackAddToCart = (item) => {
  ReactGA.gtag(GTAG.EVENT, ACTION.ADD_TO_CART, {
    currency: item.total.currency,
    value: item.total.amount,
    items: mapCartItems(item.orderItems),
  });
};

export const trackBeginCheckout = (order) => {
  ReactGA.gtag(GTAG.EVENT, ACTION.BEGIN_CHECKOUT, {
    currency: order.total.currency,
    value: order.total.amount,
    items: mapCartItems(order.orderItems),
  });
};

export const trackAddShippingInfo = (order) => {
  ReactGA.gtag(GTAG.EVENT, ACTION.ADD_SHIPPING_INFO, {
    currency: order.total.currency,
    value: order.total.amount,
    items: mapCartItems(order.orderItems),
  });
};

export const trackAddPaymentInfo = (paymentServiceType, order) => {
  ReactGA.gtag(GTAG.EVENT, ACTION.ADD_PAYMENT_INFO, {
    currency: order.total.currency,
    value: order.total.amount,
    payment_type: paymentServiceType,
    items: mapCartItems(order.orderItems),
  });
};

export const trackPurchase = (order) => {
  ReactGA.gtag(GTAG.EVENT, ACTION.PURCHASE, {
    transaction_id: order.order_number,
    value: order.total.amount,
    tax: order.totalTax.amount,
    shipping: order.totalShipping.amount,
    currency: order.total.currency,
    items: mapCartItems(order.orderItems),
  });
};

export const trackTimeOnCartPage = (timeAmount) => {
  ReactGA.gtag(GTAG.EVENT, ACTION.TIME_ON_CART_PAGE, {
    value: timeAmount,
  });
};

export const trackCartExit = () => {
  ReactGA.gtag(GTAG.EVENT, ACTION.CART_EXIT, {});
};

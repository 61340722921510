/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function Whatsappicon(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="24px"
      height="24px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 24, height: 24 }}
      paths={[
        {
          d: "M11.9997 0C5.37286 0 0 5.37139 0 11.9982C0 14.3381 0.679788 16.5158 1.83898 18.3613L0.0762286 22.6173C-0.134645 23.127 0.1074 23.7111 0.617387 23.9238C0.87326 24.0294 1.14659 24.0213 1.38295 23.9238L5.63893 22.1602C7.48417 23.3185 9.65971 24.0001 11.9996 24.0001C18.6256 24.0001 23.9994 18.6251 23.9994 11.9982C23.9994 5.37139 18.6257 0 11.9997 0ZM11.9997 22C10.1314 22 8.29872 21.4686 6.70091 20.467L5.82613 19.9183L2.84753 21.1525L4.08172 18.1721L3.533 17.297C2.5289 15.6996 2.00005 13.8672 2.00005 11.9982C2.00005 6.48529 6.48611 1.99981 11.9997 1.99981C17.5133 1.99981 21.9994 6.48494 21.9994 11.9982C21.9994 17.5118 17.5133 22 11.9997 22Z",
          fill: "rgba(106,51,22,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0%, 0%)" },
        },
        {
          d: "M14.1197 12.7056L13.0593 13.766L10.2321 10.9377L11.2926 9.87726L9.17168 6.34274L7.75844 7.75628C6.97728 8.53743 6.97728 9.80314 7.75844 10.5843L13.4127 16.2403C14.1951 17.0215 15.4626 17.0215 16.242 16.2403L17.6576 14.8283L14.1197 12.7056Z",
          fill: "rgba(106,51,22,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0%, 0%)" },
        },
      ]}
      {...getOverrideProps(overrides, "Whatsappicon")}
      {...rest}
    ></Icon>
  );
}

import React, { Component, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchPageByUrl } from '../common/actions/pageActions';
import GlobalScss from '../../layout/style/common.scss';
import BulkOrdersScss from './BulkOrders.scss';
import CheckoutField from '../../checkout/components/CheckoutField/CheckoutField';
import { Field, Form, reduxForm, SubmissionError } from 'redux-form';
import Button from 'material-kit/components/Button';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

class BulkOrders extends Component {
  state = {
    emailSuccess: false,
  };

  static propTypes = {
    pageData: PropTypes.object,
    fetchPageByUrl: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { match, fetchPageByUrl } = this.props;
    fetchPageByUrl(match.url);
  }

  _onSubmitForm = (form) => {
    emailjs.init('Fty6c-ENJZXPuJMO0');
    emailjs
      .send('service_20rmez9', 'template_6r5iado', {
        user_email: form.user_email,
        user_firstName: form.user_firstName,
        user_lastName: form.user_lastName,
        user_numberOfGuest: form.user_numberOfGuest,
        user_eventDate: form.user_eventDate,
        message: form.message,
        form,
      })
      .then((res) => {
        console.log('Email successfully sent!');
        this.setState({ emailSuccess: true });
        setTimeout(() => {
          this.setState({ emailSuccess: false });
        }, 5000);
      })
      .catch((err) => console.log(error));
  };

  render() {
    const { emailSuccess } = this.state;
    const { pageData, page, pageFields } = this.props;

    /*
            if (!pageData || pageData.isFetching) {
                return <div>Loading...</div>;
            }

            if (pageData.error) {
                return <div>Error loading page.</div>;
            }
            */

    return (
      <div styleName="GlobalScss.Page">
        <Helmet titleTemplate="%s | Reddot Food Store">
          <title>Bulk Orders</title>
          <meta name="description" content="Bulk orders" />
          <link rel="canonical" href="/bulk-orders" />
        </Helmet>
        <div
          className="container"
          styleName="GlobalScss.Container_margin_bottom"
        >
          <div styleName="GlobalScss.Page_header GlobalScss.Page_header__padding_left_background">
            <div
              className="col-sm-6 col-md-6 col-lg-6"
              styleName="BulkOrdersScss.Title_padding"
            >
              <h1 styleName="GlobalScss.Title">
                Planning an event? We're here to help!
              </h1>
            </div>
          </div>
          <div
            className="col-sm-6 col-md-6 col-lg-6"
            styleName="GlobalScss.StaticPage_Text BulkOrdersScss.Text_top_margin"
          >
            <div>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <div styleName="GlobalScss.StaticPage_subTitle">
                Planning an event? We're here to help!
              </div>
              <p>&nbsp;</p>
              <div>
                Get in touch with our team to pre-order for your special
                occasions.
              </div>
              <p>&nbsp;</p>
              <div styleName="GlobalScss.StaticPage_subTitle">
                What to Expect?
              </div>
              <p>&nbsp;</p>
              <div>Our representative will go through your requirements.</div>
              <p>&nbsp;</p>
              <div styleName="GlobalScss.StaticPage_subTitle">
                How long before the event should I place the order?
              </div>
              <p>&nbsp;</p>
              <div>
                Our product ranges are either made on order or in small batches,
                so make sure to reach out to us well in advance!
              </div>
              <p>&nbsp;</p>
              <div>
                You can also{' '}
                <strong>
                  WhatsApp Us at
                  <br></br>
                  +353 89 956 9854{' '}
                </strong>{' '}
                for prompt assistance.
              </div>
            </div>
          </div>

          <div
            className="col-sm-6 col-md-6 col-lg-6 "
            styleName="GlobalScss.ContactUsForm"
          >
            <div styleName="GlobalScss.Account_border_box GlobalScss.Account_border_box_margin">
              <BulkOrderForm
                onSubmit={this._onSubmitForm}
                emailSuccess={emailSuccess}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const FormValidators = {
  required: (value) => (value ? undefined : 'Required'),
};

const BulkOrderForm = reduxForm({
  enableReinitialize: true,
  form: 'ContactUsForm',
})(({ error, handleSubmit, submitSucceeded, emailSuccess }) => (
  <Form onSubmit={handleSubmit} styleName="GlobalScss.Contact_us_form">
    {emailSuccess && (
      <span className="text-success">Successfully email sent!</span>
    )}

    {error && <span className="text-danger">{error}</span>}

    <Field
      addon="email"
      component={CheckoutField}
      inputGroup={false}
      label="Email"
      placeholder="Enter your email"
      name="user_email"
      type="email"
      validate={FormValidators.required}
    />
    <Field
      component={CheckoutField}
      inputGroup={false}
      label="First Name"
      name="user_firstName"
      placeholder="Enter your first name"
      type="text"
      validate={FormValidators.required}
    />
    <Field
      component={CheckoutField}
      inputGroup={false}
      label="Last Name"
      name="user_lastName"
      type="text"
      placeholder="Enter your last name"
      validate={FormValidators.required}
    />
    <Field
      component={CheckoutField}
      inputGroup={false}
      label="Number of guests"
      name="user_numberOfGuest"
      type="number"
      placeholder="Enter number of guests"
      validate={FormValidators.required}
    />
    <Field
      component={CheckoutField}
      inputGroup={false}
      label="Event date"
      name="user_eventDate"
      type="date"
      placeholder="Enter event date"
      validate={FormValidators.required}
    />
    <Field
      component={CheckoutField}
      inputGroup={false}
      label="Message"
      name="message"
      type="textArea"
      placeholder="Enter your message here"
      validate={FormValidators.required}
    />

    <Button
      type="submit"
      styleName="GlobalScss.Contact_us_submit_button"
      primary
    >
      Submit
    </Button>
  </Form>
));

const mapStateToProps = (state, ownProps) => ({
  pageData: state.page[ownProps.match.url],
});

const mapDispatchToProps = {
  fetchPageByUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkOrders);

/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function Addnotes(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="24px"
      height="24px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 24, height: 24 }}
      paths={[
        {
          d: "M0 18L0 0L18 0L18 8.7C17.6833 8.55 17.3583 8.42083 17.025 8.3125C16.6917 8.20417 16.35 8.125 16 8.075L16 2L2 2L2 16L8.05 16C8.1 16.3667 8.17917 16.7167 8.2875 17.05C8.39583 17.3833 8.525 17.7 8.675 18L0 18ZM2 16L2 2L2 8.075L2 8L2 16ZM4 14L8.075 14C8.125 13.65 8.20417 13.3083 8.3125 12.975C8.42083 12.6417 8.54167 12.3167 8.675 12L4 12L4 14ZM4 10L10.1 10C10.6333 9.5 11.2292 9.08333 11.8875 8.75C12.5458 8.41667 13.25 8.19167 14 8.075L14 8L4 8L4 10ZM4 6L14 6L14 4L4 4L4 6ZM15 20C13.6167 20 12.4375 19.5125 11.4625 18.5375C10.4875 17.5625 10 16.3833 10 15C10 13.6167 10.4875 12.4375 11.4625 11.4625C12.4375 10.4875 13.6167 10 15 10C16.3833 10 17.5625 10.4875 18.5375 11.4625C19.5125 12.4375 20 13.6167 20 15C20 16.3833 19.5125 17.5625 18.5375 18.5375C17.5625 19.5125 16.3833 20 15 20ZM14.5 18L15.5 18L15.5 15.5L18 15.5L18 14.5L15.5 14.5L15.5 12L14.5 12L14.5 14.5L12 14.5L12 15.5L14.5 15.5L14.5 18Z",
          fill: "rgba(106,51,22,1)",
          fillRule: "nonzero",
          style: { transform: "translate(12.5%, 12.5%)" },
        },
      ]}
      {...getOverrideProps(overrides, "Addnotes")}
      {...rest}
    ></Icon>
  );
}

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setLocaleCountry } from 'layout/actions';
import { withToggleControls } from 'material-kit/components/Accordion';
import ReactCountryFlag from 'react-country-flag';
import find from 'lodash/find';
import Cookies from 'js-cookie';
import request from 'core/util/superagent';
import CounryselectorMenuitem from '../../../ui-components/CounryselectorMenuitem';

const defaultLocales = [
  {
    altText: 'United States',
    imageSrc: '/static/img/flags/United-States.png',
    localeCode: 'en-US',
    title: 'English',
  },
  {
    altText: 'United Kingdom',
    imageSrc: '/static/img/flags/United-Kingdom.png',
    localeCode: 'en-GB',
    title: 'English (UK)',
  },
  {
    altText: 'Mexico',
    imageSrc: '/static/img/flags/Mexico.png',
    localeCode: 'es-MX',
    title: 'Spanish (Mexico)',
  },
  {
    altText: 'Spain',
    imageSrc: '/static/img/flags/Spain.png',
    localeCode: 'es-ES',
    title: 'Spanish (Spain)',
  },
  {
    altText: 'France',
    imageSrc: '/static/img/flags/France.png',
    localeCode: 'fr-FR',
    title: 'French (France)',
  },
];

const counryselectorMenuitemOverrides = {
  CounryselectorMenuitem: {
    style: {
      cursor: 'pointer',
    },
  },
};

const LocaleCountrySelector = ({
  collapsed,
  countryCodeFromState,
  setLocaleCountry,
  setCountries,
  overrides,
  searchTerm = '',
}) => {

  const getLocaleFromCookies = () => {
    const blLocaleDefault = Cookies.get('blLocaleDefault');
    let expires = new Date();
    expires.setTime(expires.getTime() + 10 * 60 * 1000);
    let expiresForOneYear = new Date();
    expiresForOneYear.setTime(expiresForOneYear.getTime() + 365 * 24 * 60 * 60 * 1000);

    if (blLocaleDefault != null) {
      if (Cookies.get('blCountry') === blLocaleDefault) {
        Cookies.set('blLocaleDefault', 'EXPIRED', { expires: expiresForOneYear, path: '/' });
        return blLocaleDefault;
      } else if (blLocaleDefault !== 'EXPIRED') {
        Cookies.set('blLocaleDefault', 'EXPIRED', { expires: expiresForOneYear, path: '/' });
        Cookies.set('blCountry', blLocaleDefault, { expires: expiresForOneYear, path: '/' });
        Cookies.set('blCurrency', 'EUR', { expires: expiresForOneYear, path: '/' });
        return blLocaleDefault;
      }
    }
    return null;
  };
  const [defaultCountries, setDefaultCountries] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [currentState, setCurrentState] = useState('Default');

  useEffect(() => {
    const filtered = defaultCountries.filter(
      (country) =>
        country?.countryName
          ?.toLowerCase()
          .includes(searchTerm?.toLowerCase()) ||
        country?.countryCurrency
          ?.toLowerCase()
          .includes(searchTerm?.toLowerCase())
    );
    setFilteredCountries(filtered);
  }, [searchTerm, defaultCountries]);

  const fetchLocaleCountries = async () => {
    try {
      const response = await request.get('/api/menu/localeCountries');
      if (response.body && response.body.localeCountryWrappers) {
        setDefaultCountries(response.body.localeCountryWrappers);
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  useEffect(() => {
    fetchLocaleCountries();
  }, []);

  let expires = new Date();
  expires.setTime(expires.getTime() + 10 * 60 * 1000);

  let expiresForOneYear = new Date();
  expiresForOneYear.setTime(expiresForOneYear.getTime() + 365 * 24 * 60 * 60 * 1000);

  const loadDataOnlyOnce = () => {
    const interval = setInterval(() => {
      const blLocaleDefault = Cookies.get('blLocaleDefault');

      if (blLocaleDefault != null) {
        clearInterval(interval);
        if (Cookies.get('blCountry') === blLocaleDefault) {
          Cookies.set('blLocaleDefault', 'EXPIRED', { expires: expiresForOneYear, path: '/' });
        } else if (blLocaleDefault !== 'EXPIRED') {
          Cookies.set('blLocaleDefault', 'EXPIRED', { expires: expiresForOneYear, path: '/' });
          Cookies.set('blCountry', blLocaleDefault, { expires: expiresForOneYear, path: '/' });
          Cookies.set('blCurrency', 'EUR', { expires: expiresForOneYear, path: '/' });
          setLocaleCountry(blLocaleDefault, null);
        }
      }
    }, 100);
  };

  useEffect(() => {
    loadDataOnlyOnce();
  }, []);

  const currentLocaleSelection = find(defaultCountries, {
    countryCode: countryCodeFromState,
  });

  /*return (
    <li
      className={classNames({
        'dropdown languages': true,
        open: !collapsed,
      })}
    >
      <select
        style={{
          borderRadius: '50px',
          height: '50px',
          paddingLeft: '10',
          border: '1px solid rgba(0,0,0,0.05)',
          width: '100%',
        }}
        value={currentLocaleSelection ? currentLocaleSelection.countryCode : ''}
        onChange={(e) => {
          const selectedCountryCode = e.target.value;
          const selectedCountry = find(defaultCountries, { countryCode: selectedCountryCode });
          setLocaleCountry(selectedCountry.countryCode, selectedCountry.countryCurrency);
        }}
      >
        {defaultCountries.map(({ countryCode, countryName }) => (
          <option key={countryCode} value={countryCode}>
            {countryName}
          </option>
        ))}
      </select>
    </li>
  );*/
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [activeIndex, setActiveIndex] = useState(Cookies.get('blCountry'));

  const handleOnClick = (countryCode) => {
    setActiveIndex(countryCode);
    const selectedCountry = find(defaultCountries, { countryCode });
    setLocaleCountry(
      selectedCountry.countryCode,
      selectedCountry.countryCurrency
    );
  };

  return (
    <>
      {filteredCountries.map((country, index) => (
        <CounryselectorMenuitem
          countryLabel={country.countryName}
          currencyLabel={country.countryCurrency}
          key={`${country.countryName}-${country.countryCurrency}`}
          flagplaceholder={
            <ReactCountryFlag
              countryCode={country.countryCode}
              svg
              cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
              cdnSuffix="svg"
              title={country.countryCode}
              style={{
                borderRadius: '100%',
                height: '20px',
                width: '20px',
                padding: 0,
              }}
            />
            // <img
            //   src={
            //     defaultLocales.find(
            //       (loc) => loc.altText === country.countryName
            //     )?.imageSrc || '/static/img/flags/United-States.png'
            //   }
            //   alt={country.countryName}
            // />
          }
          state={
            hoveredIndex === index
              ? 'Hover'
              : activeIndex === country.countryCode
                ? 'Active'
                : currentState
          }
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
          overrides={{
            ...counryselectorMenuitemOverrides,
            ...overrides,
          }}
          onClick={() => handleOnClick(country.countryCode)}
          showCheck={
            hoveredIndex === index && activeIndex === country.countryCode
          }
          // state={currentState}
          // onMouseEnter={() => setCurrentState('Hover')}
          // onMouseLeave={() =>
          //   setCurrentState(
          //     country.countryCode === currentLocale ? 'Active' : 'Default'
          //   )
          // }
          // flagSrc={
          //   defaultLocales.find(
          //     (locale) => locale.localeCode === country.countryCode
          //   )?.imageSrc
          // }
        />
      ))}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    countryCodeFromState: state.localeCountry.countryCode,
  };
};

export default connect(mapStateToProps, { setLocaleCountry })(
  withToggleControls(LocaleCountrySelector)
);

/*
 * #%L
 * React Site Starter
 * %%
 * Copyright (C) 2009 - 2017 Broadleaf Commerce
 * %%
 * Broadleaf Commerce React Starter
 *
 * Written in 2017 by Broadleaf Commerce info@broadleafcommerce.com
 *
 * To the extent possible under law, the author(s) have dedicated all copyright and related and neighboring rights to this software to the public domain worldwide. This software is distributed without any warranty.
 * You should have received a copy of the CC0 Public Domain Dedication along with this software. If not, see <http://creativecommons.org/publicdomain/zero/1.0/>.
 *
 * Please Note - The scope of CC0 Public Domain Dedication extends to Broadleaf Commerce React Starter demo application alone. Linked libraries (including all Broadleaf Commerce Framework libraries) are subject to their respective licenses, including the requirements and restrictions specified therein.
 * #L%
 */
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isCsrMode } from 'csr/selectors';
import { isPreviewing } from 'preview/selectors';
import { Helmet } from 'react-helmet';
import AccountMenu from 'account/components/AccountMenu';
import Navbar from 'material-kit/components/Navbar';
import RDHeader from 'layout/components/Header/Header';
import Footer from '../../../ui-components/Footer';
import MessagePortal from 'layout/components/MessagePortal';
import classNames from 'classnames';
import AccountLayoutStyle from './AccountLayout.module.scss';
import { removeFromCart, updateQuantity, fetchCart } from 'cart/actions';
import { fetchMenu } from 'menu/actions';
import { isAnonymous } from 'auth/selectors';

const footerOverrides = {
  'Payment method icons': {
    maxWidth: 420,
    width: '100%',
    style: {
      flexWrap: 'wrap',
    },
  },
  FooterContent: {
    style: {
      display: 'flex',
      'flex-wrap': 'wrap',
    },
  },
  Footer: {
    width: '100%',
    style: {
      width: '100%',
      maxWidth: '1440px',
    },
  },
  legal: {
    width: '200px',
  },
  'contact&help': {
    width: '200px',
  },
  youraccount: {
    width: '200px',
  },
  aboutus: {
    width: '200px',
  },
  Copyrights: {
    maxWidth: '455px',
    width: '100%',
  },
  Facebookicon: {
    style: {
      cursor: 'pointer',
    },
  },
  LinkedInicon: {
    style: {
      cursor: 'pointer',
    },
  },
  Instagramicon: {
    style: {
      cursor: 'pointer',
    },
  },
  Whatsappicon: {
    style: {
      cursor: 'pointer',
    },
  },
  TextLink4470615: {
    style: {
      cursor: 'pointer',
    },
  },
  TextLink4470609: {
    style: {
      cursor: 'pointer',
    },
  },
  TextLink4470621: {
    style: {
      cursor: 'pointer',
    },
  },
  TextLink4470627: {
    style: {
      cursor: 'pointer',
    },
  },
  TextLink4470651: {
    style: {
      cursor: 'pointer',
    },
  },
  TextLink4470645: {
    style: {
      cursor: 'pointer',
    },
  },
  TextLink4470639: {
    style: {
      cursor: 'pointer',
    },
  },
  TextLink4470633: {
    style: {
      cursor: 'pointer',
    },
  },
  TextLink4470669: {
    style: {
      cursor: 'pointer',
    },
  },
  TextLink4470663: {
    style: {
      cursor: 'pointer',
    },
  },
  TextLink4470657: {
    justifyContent: 'flex-start',
    style: {
      cursor: 'pointer',
    },
  },
  TextLink4470681: {
    style: {
      cursor: 'pointer',
    },
  },
  TextLink4470675: {
    style: {
      cursor: 'pointer',
    },
  },
};

const AccountLayout = ({
  history,
  children,
  menu,
  cart,
  removeFromCart,
  fetchCart,
  updateQuantity,
  fetchMenu,
  anonymous,
}) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      import('@aws-amplify/ui-react/styles.css');
    }
  }, []);
  return (
    <div className={AccountLayoutStyle.AccountLayout}>
      <Helmet titleTemplate="%s | Reddot Food Store">
        <title>Account</title>
        <meta name="description" content="Account" />
      </Helmet>

      <Navbar className={AccountLayoutStyle.AccountLayout__navbar} top>
        <div className={classNames('common__GridWrapper')}>
          <RDHeader
            cart={cart}
            removeFromCart={removeFromCart}
            updateQuantity={updateQuantity}
            history={history}
            menu={menu}
            fetchCart={fetchCart}
            fetchMenu={fetchMenu}
            anonymous={anonymous}
          />
        </div>
      </Navbar>

      <section
        className={classNames(
          AccountLayoutStyle.AccountLayout__content,
          'main'
        )}
        role="main"
        id="AppContent"
      >
        <div className="section">
          <div
            className={classNames(
              AccountLayoutStyle.AccountLayoutContentContainer,
              'container'
            )}
          >
            <AccountMenu history={history} />

            <div className="col-sm-8">
              <div>{children}</div>
            </div>
          </div>
        </div>
      </section>

      <MessagePortal />

      <Footer
        overrides={footerOverrides}
        logoSrc="/static/img/Logo_Rdfood_Final.svg"
        revolutLogoSrc="/static/img/RevolutPay-Logotype-Black.svg"
        mastercardLogoSrc="/static/img/MasterCardicon.svg"
        applePayLogoSrc="/static/img/ApplePayicon.svg"
        gPayLogoSrc="/static/img/GooglePayicon.svg"
        visaLogoSrc="/static/img/Visaicon.svg"
        onLinkedinIconClick={() =>
          window.open('https://www.linkedin.com/company/rdfood')
        }
        onInstagramIconClick={() =>
          window.open('https://www.instagram.com/reddot_food/')
        }
        onFacebookIconClick={() =>
          window.open('https://www.facebook.com/reddotfood')
        }
        onWhatsappIconClick={() =>
          window.open('https://api.whatsapp.com/send?phone=353899569854')
        }
      />
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    csrMode: isCsrMode(state),
    previewing: isPreviewing(state),
    cart: state.cart,
    menu: state.menu,
    anonymous: isAnonymous(state, props),
  };
};

const mapDispatchToProps = {
  removeFromCart,
  fetchCart,
  fetchMenu,
  updateQuantity,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountLayout)
);

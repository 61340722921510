/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Icon, Text, useBreakpointValue } from "@aws-amplify/ui-react";
import ButtonIcon from "./ButtonIcon";
export default function Searchoverlay(props) {
  const {
    onClose,
    placeholder,
    inputRef,
    onInputClick,
    onInputChange,
    inputGroup,
    overrides: overridesProp,
    ...restProp
  } = props;
  const variants = [
    {
      overrides: {
        label: {},
        descriptiveText: {},
        placeholder: {},
        "Button Icon": {},
        Button: {},
        Input: {},
        border: {},
        InputGroup: {},
        Searchoverlay: {},
      },
      variantValues: { breakpoint: "base" },
    },
    {
      overrides: {
        label: {},
        descriptiveText: {},
        placeholder: {},
        "Button Icon": {},
        Button: {},
        Input: {},
        border: {
          viewBox: { minX: 0, minY: 0, width: 567, height: 1 },
          paths: [
            {
              d: "M0 0L567 0L567 -1L0 -1L0 0Z",
              stroke: "rgba(137,148,159,1)",
              fillRule: "nonzero",
              strokeWidth: 1,
            },
          ],
        },
        InputGroup: {},
        Searchoverlay: { width: "599px" },
      },
      variantValues: { breakpoint: "small" },
    },
    {
      overrides: {
        label: {},
        descriptiveText: {},
        placeholder: {},
        "Button Icon": {},
        Button: {},
        Input: {},
        border: {
          viewBox: { minX: 0, minY: 0, width: 1207, height: 1 },
          paths: [
            {
              d: "M0 0L1207 0L1207 -1L0 -1L0 0Z",
              stroke: "rgba(137,148,159,1)",
              fillRule: "nonzero",
              strokeWidth: 1,
            },
          ],
        },
        InputGroup: {},
        Searchoverlay: { width: "1279px", padding: "16px 36px 16px 36px" },
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        label: {},
        descriptiveText: {},
        placeholder: {},
        "Button Icon": {},
        Button: {},
        Input: {},
        border: {
          viewBox: { minX: 0, minY: 0, width: 1311, height: 1 },
          paths: [
            {
              d: "M0 0L1311 0L1311 -1L0 -1L0 0Z",
              stroke: "rgba(137,148,159,1)",
              fillRule: "nonzero",
              strokeWidth: 1,
            },
          ],
        },
        InputGroup: { height: "48px", padding: "16px 0px 16px 0px" },
        Searchoverlay: {
          width: "1439px",
          height: "112px",
          padding: "16px 64px 16px 64px",
        },
      },
      variantValues: { breakpoint: "large" },
    },
    {
      overrides: {
        label: {},
        descriptiveText: {},
        placeholder: {},
        "Button Icon": {},
        Button: {},
        Input: {},
        border: {
          viewBox: { minX: 0, minY: 0, width: 1792, height: 1 },
          paths: [
            {
              d: "M0 0L1792 0L1792 -1L0 -1L0 0Z",
              stroke: "rgba(137,148,159,1)",
              fillRule: "nonzero",
              strokeWidth: 1,
            },
          ],
        },
        InputGroup: { height: "48px", padding: "16px 0px 16px 0px" },
        Searchoverlay: {
          width: "1920px",
          height: "112px",
          padding: "16px 64px 16px 64px",
        },
      },
      variantValues: { breakpoint: "xxl" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    small: "small",
    medium: "medium",
    large: "large",
    xxl: "xxl",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="8px"
      direction="column"
      width="479px"
      height="77px"
      justifyContent="center"
      alignItems="flex-start"
      position="relative"
      padding="16px 16px 16px 16px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...getOverrideProps(overrides, "Searchoverlay")}
      {...rest}
    >
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="400"
        color="rgba(48,64,80,1)"
        lineHeight="24px"
        textAlign="left"
        display="none"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Label"
        {...getOverrideProps(overrides, "label")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="400"
        color="rgba(48,64,80,1)"
        fontStyle="italic"
        lineHeight="20px"
        textAlign="left"
        display="none"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore."
        {...getOverrideProps(overrides, "descriptiveText")}
      ></Text>
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "InputGroup")}
      >
        <Flex
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          borderRadius="4px"
          padding="0px 0px 0px 16px"
          display="flex"
          ref={inputRef}
          onChange={onInputChange}
          onClick={onInputClick}
          {...getOverrideProps(overrides, "Input")}
        >
          <Text
            fontFamily="Montserrat"
            fontSize="14px"
            label={placeholder}
            fontWeight="400"
            color="rgba(128,128,128,1)"
            lineHeight="21px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={inputGroup}
            {...getOverrideProps(overrides, "placeholder")}
          ></Text>
          <Flex
            gap="8px"
            direction="row"
            width="unset"
            height="48px"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="8px 16px 8px 16px"
            display="flex"
            onClick={onClose}
            {...getOverrideProps(overrides, "Button")}
          >
            <ButtonIcon
              width="14px"
              height="14px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Button Icon")}
            ></ButtonIcon>
          </Flex>
        </Flex>
        <Icon
          width="unset"
          height="0px"
          viewBox={{ minX: 0, minY: 0, width: 447, height: 1 }}
          paths={[
            {
              d: "M0 0L447 0L447 -1L0 -1L0 0Z",
              stroke: "rgba(137,148,159,1)",
              fillRule: "nonzero",
              strokeWidth: 1,
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          {...getOverrideProps(overrides, "border")}
        ></Icon>
      </Flex>
    </Flex>
  );
}

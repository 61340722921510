/*
 * #%L
 * React Site Starter
 * %%
 * Copyright (C) 2009 - 2017 Broadleaf Commerce
 * %%
 * Broadleaf Commerce React Starter
 *
 * Written in 2017 by Broadleaf Commerce info@broadleafcommerce.com
 *
 * To the extent possible under law, the author(s) have dedicated all copyright and related and neighboring rights to this software to the public domain worldwide. This software is distributed without any warranty.
 * You should have received a copy of the CC0 Public Domain Dedication along with this software. If not, see <http://creativecommons.org/publicdomain/zero/1.0/>.
 *
 * Please Note - The scope of CC0 Public Domain Dedication extends to Broadleaf Commerce React Starter demo application alone. Linked libraries (including all Broadleaf Commerce Framework libraries) are subject to their respective licenses, including the requirements and restrictions specified therein.
 * #L%
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Price from 'material-kit/components/Price';
import flatMap from 'lodash/flatMap';
import isEmpty from 'lodash/isEmpty';
import ViewHistoryOrdersDetailsScss from './OrderHistoryDetails.scss';
import { connect } from 'react-redux';
import { fetchOrderHistory } from 'account/actions';
import { resolve } from 'core/decorator/reduxResolve';
import { getOrders } from 'account/selectors';
import GlobalScss from '../../../layout/style/common.scss';

class OrderHistoryDetails extends PureComponent {
  static propTypes = {
    orders: PropTypes.array,
  };

  render() {
    const order =
      this.props.orders &&
      this.props.orders.find(
        (order) => order.orderNumber == this.props.match.params.orderNumber
      );
    const { fulfillmentGroups, orderNumber, submitDate } = order || {};
    return (
      <div>
        <div styleName="GlobalScss.Page_header">
          <div styleName="GlobalScss.Title">Order Details</div>
        </div>
        <div styleName="GlobalScss.Text_below_header">
          Thank you for your order! See details below.
        </div>
        <div>
          <OrderHistoryDetails.Main
            orderNumber={orderNumber}
            submitDate={submitDate}
          />
        </div>
        <br />
        <div>
          {!isEmpty(fulfillmentGroups) &&
            fulfillmentGroups.map((fulfillmentGroup) => (
              <OrderHistoryDetails.FulfillmentGroup
                key={fulfillmentGroup.id}
                {...fulfillmentGroup}
              />
            ))}
        </div>
        <br />
        <div>
          <OrderHistoryDetails.Summary {...order} />
        </div>
        <br />
        <div>
          <OrderHistoryDetails.Payment {...order} />
        </div>
        <br />
        <div>
          <OrderHistoryDetails.Total {...order} />
        </div>
      </div>
    );
  }
}

OrderHistoryDetails.Summary = ({
  status,
  orderItems,
  subTotal,
  totalShipping,
  totalTax,
  total,
}) => {
  return (
    <div styleName="ViewHistoryOrdersDetailsScss.OrderTotalHeaderText">
      <div
        className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
        styleName="ViewHistoryOrdersDetailsScss.OrderStatusHeaderParent"
      >
        <div
          className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
          styleName="ViewHistoryOrdersDetailsScss.OrderStatusParent"
        >
          <div
            className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
            styleName="ViewHistoryOrdersDetailsScss.OrderSummaryHeaderText"
          >
            ORDER STATUS:
          </div>
          <div styleName="ViewHistoryOrdersDetailsScss.OrderStatusHeaderText">
            {status}!
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <h5 styleName="ViewHistoryOrdersDetailsScss.OrderItemNumber">
            {orderItems && orderItems.length}{' '}
            {orderItems && orderItems.length === 1 ? 'Item' : 'Items'}
          </h5>
        </div>
      </div>
      <div className="row">
        <div
          className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
          styleName="ViewHistoryOrdersDetailsScss.OrderHistorySummaryParent"
        >
          <div styleName="ViewHistoryOrdersDetailsScss.OrderHistoryDetails">
            {!isEmpty(orderItems) &&
              orderItems.map((orderItem) => {
                const itemRow = (
                  <div
                    className="row"
                    styleName="ViewHistoryOrdersDetailsScss.OrderItemImageParent"
                  >
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div styleName="ViewHistoryOrdersDetailsScss.CartOrderItem__image">
                        <img
                          src={orderItem.primaryMedia.url}
                          alt={orderItem.primaryMedia.altText}
                          styleName="ViewHistoryOrdersDetailsScss.OrderItemImage"
                        />
                      </div>
                    </div>
                    <div
                      styleName="ViewHistoryOrdersDetailsScss.OrderItemNamePriceText"
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    >
                      {`${orderItem.name}`}
                    </div>
                    <div
                      styleName="ViewHistoryOrdersDetailsScss.OrderItemNamePriceText"
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    >
                      <Price price={orderItem.totalPrice} />
                    </div>
                    {orderItem.orderItemAttributes &&
                      orderItem.orderItemAttributes.map((orderAttribute) => (
                        <div
                          styleName="ViewHistoryOrdersDetailsScss.OrderItemOptionParent"
                          className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                        >
                          <div
                            styleName="ViewHistoryOrdersDetailsScss.OrderItemOptionText"
                            className="col-lg-2 col-md-3 col-sm-6 col-xs-6"
                          >
                            {`${orderAttribute.name}:`}
                          </div>
                          <div
                            styleName="ViewHistoryOrdersDetailsScss.OrderItemOptionValue"
                            className="col-lg-2 col-md-3 col-sm-6 col-xs-6"
                          >
                            {`${orderAttribute.value}`}
                          </div>
                        </div>
                      ))}
                    <div
                      styleName="ViewHistoryOrdersDetailsScss.OrderItemOptionParent"
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    >
                      <div
                        styleName="ViewHistoryOrdersDetailsScss.OrderItemOptionText"
                        className="col-lg-2 col-md-3 col-sm-6 col-xs-6"
                      >
                        QUANTITY:
                      </div>
                      <div
                        styleName="ViewHistoryOrdersDetailsScss.OrderItemOptionValue"
                        className="col-lg-2 col-md-3 col-sm-6 col-xs-6"
                      >
                        {`X ${orderItem.quantity}`}
                      </div>
                    </div>
                  </div>
                );

                const appliedPromotionMessages =
                  getAppliedPromotionMessages(orderItem);
                const promotionRow = !isEmpty(appliedPromotionMessages) && (
                  <tr>
                    <td>
                      <div styleName="ViewHistoryOrdersDetailsScss.OrderHistoryDetails__promotionRow">
                        <ul className="promotion">
                          {appliedPromotionMessages.map((message, index) => (
                            <li key={index}>
                              <span className="promotion-applied">{`Promotion Applied: ${message}`}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </td>
                    <td></td>
                  </tr>
                );

                return [itemRow, promotionRow];
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

function getAppliedPromotionMessages(orderItem) {
  const { orderItemPriceDetails, totalAdjustmentValue } = orderItem;

  if (!totalAdjustmentValue || totalAdjustmentValue.amount === '0.00') {
    return [];
  }

  return flatMap(orderItemPriceDetails, (priceDetail) => {
    if (!priceDetail.adjustment) {
      return [];
    }

    return priceDetail.adjustment
      .map((adjustment) => adjustment.marketingMessage)
      .filter((a) => a);
  });
}

OrderHistoryDetails.FulfillmentGroup = ({
  address,
  fulfillmentOption,
  fulfillmentGroupItems,
  status,
  type,
}) => (
  <div styleName="ViewHistoryOrdersDetailsScss.OrderTotalHeaderText">
    {!['GIFT_CARD', 'DIGITAL', 'PHYSICAL_PICKUP'].includes(type) && (
      <div>
        <div
          styleName="ViewHistoryOrdersDetailsScss.OrderSummaryHeaderText"
          className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12"
        >
          DELIVERY ADDRESS
        </div>
        <div className="row">
          <div
            className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
            styleName="ViewHistoryOrdersDetailsScss.OrderAddressSummaryParent"
          >
            <div className="shipping-information">
              <div className="fulfillment-group-address">
                {address && (
                  <React.Fragment>
                    <div
                      styleName="ViewHistoryOrdersDetailsScss.AddressItemValue"
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    >
                      {address.firstName}&nbsp;{address.lastName}
                    </div>
                    <div
                      styleName="ViewHistoryOrdersDetailsScss.AddressItemValue"
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    >
                      {address.addressLine1}
                    </div>
                    <div
                      styleName="ViewHistoryOrdersDetailsScss.AddressItemValue"
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    >
                      {address.addressLine2}
                    </div>
                    <div
                      styleName="ViewHistoryOrdersDetailsScss.AddressItemValue"
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    >
                      {address.city}
                    </div>
                    <div
                      styleName="ViewHistoryOrdersDetailsScss.AddressItemValue"
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    >
                      {address.stateProvinceRegion}
                    </div>
                    <div
                      styleName="ViewHistoryOrdersDetailsScss.AddressItemValue"
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    >
                      {address.postalCode}
                    </div>
                    <div
                      styleName="ViewHistoryOrdersDetailsScss.AddressItemValue"
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    >
                      {address.country && address.country.name}
                    </div>
                    <div
                      styleName="ViewHistoryOrdersDetailsScss.AddressItemValue"
                      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    >
                      {address.phonePrimary && (
                        <React.Fragment>
                          {address.phonePrimary.phoneNumber}
                          <br key={0} />
                        </React.Fragment>
                      )}
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
);

OrderHistoryDetails.Main = ({ orderNumber, submitDate }) => {
  var formattedDate = new Date(submitDate).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });
  formattedDate = formattedDate.slice(0, 6) + ', ' + formattedDate.slice(6);
  return (
    <div styleName="ViewHistoryOrdersDetailsScss.OrderTotalHeaderText">
      <div
        className="row"
        styleName="ViewHistoryOrdersDetailsScss.OrderSummaryParentDiv"
      >
        <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
          <div
            styleName="ViewHistoryOrdersDetailsScss.OrderSummaryHeaderText"
            className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12"
          >
            ORDER NUMBER:
          </div>
          <div
            styleName="ViewHistoryOrdersDetailsScss.OrderSummaryHeaderValue"
            className="col-xl-8 col-lg-6 col-md-6 col-sm-12 col-xs-12"
          >
            {`${orderNumber}`}
          </div>
        </div>
        <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
          <div
            styleName="ViewHistoryOrdersDetailsScss.OrderSummaryHeaderText"
            className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
          >
            ORDER DATE:
          </div>
          <div
            styleName="ViewHistoryOrdersDetailsScss.OrderSummaryHeaderValue"
            className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
          >
            {`${formattedDate}`}
          </div>
        </div>
        <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <div
            className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
            styleName="ViewHistoryOrdersDetailsScss.ViewOrdersLinkParentDiv"
          >
            <a
              href="/account/orders"
              styleName="ViewHistoryOrdersDetailsScss.ViewOrdersLinkText"
            >
              Return Info
              <img
                src={'/static/img/secondaryArrowIcon.svg'}
                styleName="ViewHistoryOrdersDetailsScss.ViewOrdersLinkImage"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

OrderHistoryDetails.Payment = ({ payments, type }) => (
  <div styleName="ViewHistoryOrdersDetailsScss.OrderTotalHeaderText">
    {payments && ['COD', 'REVOLUT'].includes(payments[0].type) && (
      <div>
        <div
          styleName="ViewHistoryOrdersDetailsScss.OrderSummaryHeaderText"
          className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12"
        >
          PAYMENT DETAILS
        </div>
        <div className="row">
          <div
            className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
            styleName="ViewHistoryOrdersDetailsScss.OrderPaymentSummaryParent"
          >
            <div styleName="ViewHistoryOrdersDetailsScss.OrderHistoryPaymentDetails">
              <div className="row">
                <div
                  className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                  styleName="ViewHistoryOrdersDetailsScss.OrderPaymentParent"
                >
                  <div
                    className="col-lg-3 col-md-6 col-sm-6 col-xs-6"
                    styleName="ViewHistoryOrdersDetailsScss.OrderPayment"
                  >
                    <a
                      href="#"
                      styleName="ViewHistoryOrdersDetailsScss.PaymentImageParent"
                    >
                      <img
                        src={'/static/img/RevolutButtonIcon.svg'}
                        styleName="ViewHistoryOrdersDetailsScss.PaymentImage"
                      />
                    </a>
                  </div>
                  <div
                    className="col-lg-9 col-md-6 col-sm-6 col-xs-6"
                    styleName="ViewHistoryOrdersDetailsScss.PaymentDescription"
                  >
                    Revolut Pay
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
);

OrderHistoryDetails.Total = ({ subTotal, totalShipping, total }) => {
  return (
    <div styleName="ViewHistoryOrdersDetailsScss.OrderTotalHeaderText">
      <div
        styleName="ViewHistoryOrdersDetailsScss.OrderSummaryHeaderText"
        className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12"
      >
        ORDER TOTAL
      </div>
      <div
        className="row"
        styleName="ViewHistoryOrdersDetailsScss.OrderTotalRow"
      >
        <div
          className="col-sm-12"
          styleName="ViewHistoryOrdersDetailsScss.OrderTotalSummaryParent"
        >
          <div className="OrderHistoryDetails">
            <div
              className="row"
              styleName="ViewHistoryOrdersDetailsScss.OrderTotalContentParent"
            >
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div
                  styleName="ViewHistoryOrdersDetailsScss.OrderTotalSubItemText"
                  className="col-lg-9 col-md-9 col-sm-6 col-xs-6"
                >
                  SUBTOTAL
                </div>
                <div
                  styleName="ViewHistoryOrdersDetailsScss.OrderTotalSubItemValue"
                  className="col-lg-3 col-md-3 col-sm-6 col-xs-6"
                >
                  <Price className="price" price={subTotal} />
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div
                  styleName="ViewHistoryOrdersDetailsScss.OrderTotalSubItemText"
                  className="col-lg-9 col-md-9 col-sm-9 col-xs-6"
                >
                  DELIVERY CHARGES
                </div>
                <div
                  styleName="ViewHistoryOrdersDetailsScss.OrderTotalSubItemValue"
                  className="col-lg-3 col-md-3 col-sm-3 col-xs-6"
                >
                  <Price className="price" price={totalShipping} />
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div
                  styleName="ViewHistoryOrdersDetailsScss.OrderTotalSubItemText"
                  className="col-lg-9 col-md-9 col-sm-9 col-xs-6"
                >
                  VOUCHERS
                </div>
                <div
                  styleName="ViewHistoryOrdersDetailsScss.OrderTotalSubItemValue"
                  className="col-lg-3 col-md-3 col-sm-3 col-xs-6"
                >
                  None
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
          styleName="ViewHistoryOrdersDetailsScss.TotalHeaderParent"
        >
          <div
            className="col-lg-9 col-md-8 col-sm-6 col-xs-3"
            styleName="ViewHistoryOrdersDetailsScss.TotalHeaderText"
          >
            TOTAL
          </div>
          <div
            styleName="ViewHistoryOrdersDetailsScss.OrderTotalValue"
            className="col-lg-3 col-md-4 col-sm-6 col-xs-9"
          >
            <Price className="price" price={total} />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authenticationToken: state.auth.authenticationToken,
    customerToken:
      state.auth.anonymousCustomerToken || state.csr.csrCustomerToken,
    isFetching: state.orderHistory.isFetching,
    orders: getOrders(state),
  };
};

const dispatchResolve = (resolver, props) => {
  if (!props.isFetching) {
    resolver.resolve(props.fetchOrderHistory);
  }
};

export default connect(mapStateToProps, { fetchOrderHistory })(
  resolve(dispatchResolve)(OrderHistoryDetails)
);

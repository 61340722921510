import React from 'react';
import BannerWidgetStyle from './BannerWidget.module.scss';
import Button from 'material-kit/components/Button';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import GlobalScss from '../../../../layout/style/common.scss';

const BannerWidget = ({}) => {
  const lastViewed =
    (typeof window !== 'undefined' && localStorage.getItem('lastViewed')) ||
    '/';

  return (
    <div className={BannerWidgetStyle.BannerWrapper}>
      <div className={GlobalScss.GridWrapper}>
        <div className={BannerWidgetStyle.BannerContentParentDiv}>
          <div>
            <div className={BannerWidgetStyle.BanerHeaderTitleText}>
              Carefully Sourced For Exceptional Flavour
            </div>
            <div className={BannerWidgetStyle.BannerContentText}>
              Why Settle For Junk? Try Our Freshly Made!
            </div>
            <div>
              <Button
                className={BannerWidgetStyle.ShopAllButton}
                component={Link}
                componentProps={{ to: '/shop' }}
                primary
                lg
              >
                Shop all
              </Button>
              <Button
                className={BannerWidgetStyle.BulkOrdersButtonTextPosition}
                component={Link}
                componentProps={{
                  to: '/bulk-orders', // + lastViewed,
                }}
                lg
                simple
              >
                Bulk Orders
                <img src="/static/img/secondary_CTA_icon.svg" />
              </Button>
            </div>
          </div>
          <div className={BannerWidgetStyle.BicycleImgWrapper}>
            <img
              src="/static/img/home/BANNER_ASSETS/BYCYCLE.svg"
              className={BannerWidgetStyle.BicycleImg}
            />
            <img
              src="/static/img/home/BANNER_ASSETS/CLOUDS.svg"
              className={BannerWidgetStyle.CloudsImg}
            />
          </div>
        </div>
      </div>
      <div
        className={classNames(
          BannerWidgetStyle.BannerBottomTextParentDiv,
          'col-xs-12 col-sm-12 col-lg-12'
        )}
      >
        <div className="col-xs-12 col-sm-12 col-lg-12">
          <div className={BannerWidgetStyle.BanerBottomText}>
            What Sets Reddot Apart
          </div>
        </div>
      </div>
      <div
        className={classNames(
          BannerWidgetStyle.BannerWaveParentDiv,
          'col-xs-12 col-sm-12 col-lg-12'
        )}
      >
        <div className={BannerWidgetStyle.BannerWave}>
          <img
            src="/static/img/home/BANNER_ASSETS/banner_wave_fixed.svg"
            className={BannerWidgetStyle.BannerWaveImg}
          />
        </div>
      </div>
      <div className={GlobalScss.WideContentWrapper}>
        <div className={BannerWidgetStyle.WhatSetsReddotApartParentDiv}>
          <div
            className={classNames(BannerWidgetStyle.WhatSetsReddotApart, '')}
          >
            <div className={BannerWidgetStyle.WhatSetsReddotApartOverlay}>
              <span>
                <p>
                  We use whole ingredients and time-honored recipes to naturally
                  enhance our products' taste.
                </p>
                <p>
                  No additives, preservatives, or artificial flavor
                  enhancers—just pure, home-style goodness!
                </p>
              </span>
            </div>
            <div className={BannerWidgetStyle.WhatSetsReddotApartHeader}>
              <span className={BannerWidgetStyle.WhatSetsReddotApartHeaderText}>
                Wholesome Ingredients
              </span>
            </div>
            <img
              src="static/img/home/BANNER_ASSETS/Wholesome_Ingredients.webp"
              srcSet="
                        static/img/home/BANNER_ASSETS/Wholesome_Ingredients.webp 480w,
                        static/img/home/BANNER_ASSETS/Wholesome_Ingredients@2x.webp 800w
                        static/img/home/BANNER_ASSETS/Wholesome_Ingredients@3x.webp 1000w
                      "
              sizes="
                        (max-width: 600px) 480px,
                        800px
                      "
              className={BannerWidgetStyle.WhatSetsReddotApartImg}
            />
          </div>
          <div
            className={classNames(BannerWidgetStyle.WhatSetsReddotApart, '')}
          >
            <div className={BannerWidgetStyle.WhatSetsReddotApartOverlay}>
              <span>
                <p>
                  Our snack and sweet range is made to order, while our
                  condiments are crafted in small, seasonal batches.
                </p>
                <p>
                  This ensures freshness, quality, and a personal touch that
                  regular products in super markets lack.
                </p>
              </span>
            </div>
            <div className={BannerWidgetStyle.WhatSetsReddotApartHeader}>
              <span className={BannerWidgetStyle.WhatSetsReddotApartHeaderText}>
                Say Yes To Freshly Made
              </span>
            </div>
            <img
              src="static/img/home/BANNER_ASSETS/Say_Yes_to_freshly_made.webp"
              srcSet="
                        static/img/home/BANNER_ASSETS/Say_Yes_to_freshly_made.webp 480w,
                        static/img/home/BANNER_ASSETS/Say_Yes_to_freshly_made@2x.webp 800w
                        static/img/home/BANNER_ASSETS/Say_Yes_to_freshly_made@3x.webp 1000w
                      "
              sizes="
                        (max-width: 600px) 480px,
                        800px
                      "
              className={BannerWidgetStyle.WhatSetsReddotApartImg}
            />
          </div>
          <div
            className={classNames(BannerWidgetStyle.WhatSetsReddotApart, '')}
          >
            <div className={BannerWidgetStyle.WhatSetsReddotApartOverlay}>
              <span>
                <p>
                  We deliver our products in 5-7 business days with assistance
                  every step of the way.
                </p>
                <p>
                  Our pricing is transparent inclusive of all taxes and custom
                  duties.
                </p>
              </span>
            </div>
            <div className={BannerWidgetStyle.WhatSetsReddotApartHeader}>
              <span className={BannerWidgetStyle.WhatSetsReddotApartHeaderText}>
                No Hidden Charges
              </span>
            </div>
            <img
              src="static/img/home/BANNER_ASSETS/No_Hassle_deliveries.webp"
              srcSet="
                        static/img/home/BANNER_ASSETS/No_Hassle_Deliveries.webp 480w,
                        static/img/home/BANNER_ASSETS/No_Hassle_Deliveries@2x.webp 800w
                        static/img/home/BANNER_ASSETS/No_Hassle_Deliveries@3x.webp 1000w
                      "
              sizes="
                        (max-width: 600px) 480px,
                        800px
                      "
              className={BannerWidgetStyle.WhatSetsReddotApartImg}
            />
          </div>
          <div
            className={classNames(BannerWidgetStyle.WhatSetsReddotApart, '')}
          >
            <div className={BannerWidgetStyle.WhatSetsReddotApartOverlay}>
              <span>
                <p>
                  We are committed to providing quality, regionally sourced
                  grocery products.
                </p>
                <p>
                  These are hygienically cleaned and packaged to ensure no
                  adulteration.
                </p>
              </span>
            </div>
            <div className={BannerWidgetStyle.WhatSetsReddotApartHeader}>
              <span className={BannerWidgetStyle.WhatSetsReddotApartHeaderText}>
                Meticulously Sourced
              </span>
            </div>
            <img
              src="static/img/home/BANNER_ASSETS/Meticulously_Sourced.webp"
              srcSet="
                        static/img/home/BANNER_ASSETS/Meticulously_Sourced.webp 480w,
                        static/img/home/BANNER_ASSETS/Meticulously_Sourced@2x.webp 800w
                        static/img/home/BANNER_ASSETS/Meticulously_Sourced@3x.webp 1000w
                      "
              sizes="
                        (max-width: 600px) 480px,
                        800px
                      "
              className={BannerWidgetStyle.WhatSetsReddotApartImg}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerWidget;

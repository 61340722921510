import React from 'react';
import AboutUsWidgetStyle from './AboutUsWidget.module.scss';
import Button from 'material-kit/components/Button';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import GlobalScss from '../../../../layout/style/common.scss';

const AboutUsWidget = () => (
  <div className={AboutUsWidgetStyle.Wrapper} id="aboutUs">
    <div>
      <div className={GlobalScss.GridWrapper}>
        <div className={AboutUsWidgetStyle.ContentWrapper}>
          <div className={AboutUsWidgetStyle.TopSubscribeLeftText}>
            <h3>About Us</h3>
            <h2>
              Reviving flavor, ditching additives, and nourishing families—
              Reddot’s Promise.
            </h2>
            <h4>
              We are a bunch of foodies who faced a common dilemma: how to
              nourish our families with the authentic, wholesome Indian meals we
              grew up loving. Disheartened by the lack of quality and
              unnecessary additives in existing products, we embarked on a
              journey to bring a solution to tables everywhere
            </h4>
            <div>
              <Button
                className={AboutUsWidgetStyle.ReadMoreButton}
                primary
                component={Link}
                componentProps={{ to: '/shop' }}
                round
              >
                Shop All
              </Button>
              <Button
                className={AboutUsWidgetStyle.BulkOrdersButtonTextPosition}
                component={Link}
                componentProps={{ to: '/contactus' }}
                lg
                simple
              >
                Contact us
                <img src="/static/img/secondary_CTA_icon.svg" />
              </Button>
            </div>
          </div>
          <div className={AboutUsWidgetStyle.SubscribeImagesDiv}>
            <img
              src="static/img/home/ABOUT_US/Mother-daughter-eating-healthyX1.webp"
              srcSet="
                static/img/home/ABOUT_US/Mother-daughter-eating-healthyX1.webp 480w,
                static/img/home/ABOUT_US/Mother-daughter-eating-healthyX2.webp 800w
              "
              sizes="
                (max-width: 600px) 480px,
                800px
              "
              alt="Left Img"
              className={AboutUsWidgetStyle.HomeRightImg}
            />
          </div>
        </div>
      </div>
    </div>
    <div
      className={classNames(
        AboutUsWidgetStyle.AboutUsParentDiv,
        'col-xs-12 col-md-12 col-lg-12'
      )}
    >
      <div className={AboutUsWidgetStyle.AboutUsImgParentDiv}>
        <img
          className={AboutUsWidgetStyle.AboutUsImg}
          src="/static/img/home/ABOUT_US/aboutus_5.svg"
        ></img>
      </div>
    </div>

    <div
      className={classNames(
        AboutUsWidgetStyle.NewsletterDiv,
        'col-xs-12 col-md-12 col-lg-12'
      )}
    >
      <div className={classNames(GlobalScss.GridWrapper)}>
        <div className={AboutUsWidgetStyle.ContentWrapper}>
          <div className={AboutUsWidgetStyle.BottomSubscribeImagesDiv}>
            <img
              src="static/img/home/ABOUT_US/Ghee-Mysore-pak-Indian-sweetx1.webp"
              srcSet="
                static/img/home/ABOUT_US/Ghee-Mysore-pak-Indian-sweetx1.webp 480w,
                static/img/home/ABOUT_US/Ghee-Mysore-pak-Indian-sweetx2.webp 800w
              "
              sizes="
                (max-width: 600px) 480px,
                800px
              "
              alt="Left Img"
              className={AboutUsWidgetStyle.AboutUsLeftImg}
            />
          </div>
          <div className={AboutUsWidgetStyle.BottomContainer}>
            <h3>Our Mission</h3>
            <h2>
              To become the global choice for Indian cuisine, inspired by
              traditional values.
            </h2>
            <h4>
              At RedDot Food, we believe in a win-win approach that fosters
              trust and collaboration for mutually beneficial outcomes for
              customers and all our stakeholders. We uphold high standards with
              a tough but fair mindset, ensuring equity in all actions. By
              embracing a positive, can-do attitude, we turn challenges into
              opportunities. A continuous learning and growth mindset drives
              innovation and elevates our service.
            </h4>
            <div>
              <Button
                className={AboutUsWidgetStyle.ReadMoreButton}
                primary
                component={Link}
                componentProps={{ to: '/shop' }}
                round
              >
                Shop All
              </Button>
              <Button
                className={AboutUsWidgetStyle.BulkOrdersButtonTextPosition}
                component={Link}
                componentProps={{ to: '/contactus' }}
                lg
                simple
              >
                Contact Us
                <img src="/static/img/secondary_CTA_icon.svg" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AboutUsWidget;

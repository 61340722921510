/*
 * #%L
 * React Site Starter
 * %%
 * Copyright (C) 2009 - 2017 Broadleaf Commerce
 * %%
 * Broadleaf Commerce React Starter
 *
 * Written in 2017 by Broadleaf Commerce info@broadleafcommerce.com
 *
 * To the extent possible under law, the author(s) have dedicated all copyright and related and neighboring rights to this software to the public domain worldwide. This software is distributed without any warranty.
 * You should have received a copy of the CC0 Public Domain Dedication along with this software. If not, see <http://creativecommons.org/publicdomain/zero/1.0/>.
 *
 * Please Note - The scope of CC0 Public Domain Dedication extends to Broadleaf Commerce React Starter demo application alone. Linked libraries (including all Broadleaf Commerce Framework libraries) are subject to their respective licenses, including the requirements and restrictions specified therein.
 * #L%
 */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, Field, reduxForm } from 'redux-form';
import Button from 'material-kit/components/Button';
import classNames from 'classnames';
import PromoCodeStyle from './PromoCodes.module.scss';

const validators = {
  required: (value) => (value ? undefined : 'Required'),
};

const PromoCodeForm = ({ error, handleSubmit, errorMessage }) => (
  <Form onSubmit={handleSubmit}>
    <Field
      component={PromoCodeField}
      name="promoCode"
      placeholder="Add voucher code"
      label="Enter voucher code below"
      validate={validators.required}
    />

    {/* <!-- Promotion Code Error --> */}
    {errorMessage && (
      <div className={classNames('text-danger', PromoCodeStyle.error_text)}>
        {errorMessage}
      </div>
    )}
  </Form>
);

const PromoCodeField = ({
  input,
  label,
  placeholder,
  meta: { active, error, touched },
}) => (
  <div
    className={classNames(
      {
        'form-group': true,
        'is-empty': !input.value,
        'is-focused': active,
        'has-error': touched && error,
      },
      PromoCodeStyle.PromoCode__form
    )}
  >
    {/* <div className='input-group'>*/}
    <label className="control-label">
      {label}
      {touched && error && ` - ${error}`}
    </label>
    <input
      className={classNames('form-control', PromoCodeStyle.PromoCode__form)}
      {...input}
      placeholder={placeholder}
      type="text"
      autoComplete="off"
    ></input>
    <span className="material-input"></span>
    <span className="input-group-btn">
      <img
        className={classNames('pull-right', PromoCodeStyle.PromoCode__icon)}
        src="/static/img/discount-label.svg"
      ></img>
    </span>
  </div>
);

export default reduxForm({ form: 'PromoCode' })(PromoCodeForm);

/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function MobileMenuitem(props) {
  const {
    label,
    as = "a",
    href,
    onClick,
    overrides: overridesProp,
    ...rest
  } = props;
  const variants = [
    {
      overrides: { label: {}, MobileMenuitem: {} },
      variantValues: { state: "Selected" },
    },
    {
      overrides: {
        label: { fontWeight: "400", color: "rgba(106,51,22,1)" },
        MobileMenuitem: { padding: "0px 16px 0px 16px" },
      },
      variantValues: { state: "Default" },
    },
    {
      overrides: {
        label: {
          fontWeight: "400",
          color: "rgba(137,148,159,1)",
          children: "Text ",
        },
        MobileMenuitem: { padding: "0px 16px 0px 16px" },
      },
      variantValues: { state: "Disable" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="8px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="4px 16px 4px 16px"
      backgroundColor="rgba(168,104,0,1)"
      display="flex"
      onClick={onClick}
      {...getOverrideProps(overrides, "MobileMenuitem")}
      {...rest}
    >
      <Text
        fontFamily="Montserrat"
        fontSize="14px"
        fontWeight="500"
        color="rgba(255,255,255,1)"
        lineHeight="21px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={label}
        {...getOverrideProps(overrides, "label")}
      ></Text>
    </Flex>
  );
}

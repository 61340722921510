export const GTAG = {
  EVENT: 'event',
};
export const CATEGORY = {
  SEARCH: 'Search',
  ECOMMERCE: 'Ecommerce',
  CART: 'Cart',
};

export const ACTION = {
  SEARCH: 'search',
  VIEW_ITEM: 'view_item',
  ADD_TO_CART: 'add_to_cart',
  BEGIN_CHECKOUT: 'begin_checkout',
  ADD_PAYMENT_INFO: 'add_payment_info',
  ADD_SHIPPING_INFO: 'add_shipping_info',
  PURCHASE: 'purchase',
  TRANSITION_CART_TO_CHECKOUT: 'cart_to_checkout',
  TRANSITION_CART_TO_SHOP: 'cart_to_back_to_shop',
  TIME_ON_CART_PAGE: 'time_on_cart_page',
  CART_EXIT: 'cart_exit',
};

export const LABEL = {
  CART_TO_CHECKOUT: 'Cart to Checkout',
  CART_TO_SHOP: 'Cart to Shop',
  ORDER_COMPLETED: 'Order Completed',
};

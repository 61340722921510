/*
 * #%L
 * React Site Starter
 * %%
 * Copyright (C) 2009 - 2017 Broadleaf Commerce
 * %%
 * Broadleaf Commerce React Starter
 *
 * Written in 2017 by Broadleaf Commerce info@broadleafcommerce.com
 *
 * To the extent possible under law, the author(s) have dedicated all copyright and related and neighboring rights to this software to the public domain worldwide. This software is distributed without any warranty.
 * You should have received a copy of the CC0 Public Domain Dedication along with this software. If not, see <http://creativecommons.org/publicdomain/zero/1.0/>.
 *
 * Please Note - The scope of CC0 Public Domain Dedication extends to Broadleaf Commerce React Starter demo application alone. Linked libraries (including all Broadleaf Commerce Framework libraries) are subject to their respective licenses, including the requirements and restrictions specified therein.
 * #L%
 */
import React, { PureComponent, useState } from 'react';
import { connect } from 'react-redux';
import { resolve } from 'core/decorator/reduxResolve';
import { isAnonymous } from 'auth/selectors';
import { getWishlist } from 'account/selectors';
import {
  fetchWishlist,
  moveItemToCart,
  moveListToCart,
  removeItemFromWishlist,
  updateQuantityInWishlist,
} from 'account/actions';
import { fetchProductBatch } from 'catalog/product/actions';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Button from 'material-kit/components/Button';
import Price from 'material-kit/components/Price';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import range from 'lodash/range';
import { format } from 'layout/util/moneyUtils';
import ManageWishlistScss from './ManageWishlist.scss';
import GlobalScss from '../../../layout/style/common.scss';
import { addToCart } from 'cart/actions';
import classNames from 'classnames';
import ResultListItem from '../../../catalog/search/components/ResultListItem';
import ResultList from '../../../catalog/search/components/ResultList';

class ManageWishlist extends PureComponent {
  componentWillReceiveProps(nextProps) {
    if (!nextProps.anonymous) {
      fetchMissingProductsIfNeeded(nextProps);
    }
  }

  render() {
    // this tries to resolve issue described in the RE-207
    if (typeof document === 'undefined') {
      return null;
    }
    const elements = document.querySelectorAll('.WishlistItem__sku_option');
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.color = 'blue';
    }
    const orderItem = this.props.orderItems;
    return (
      <div /*styleName='Wishlist'*/>
        <div styleName="GlobalScss.Page_header">
          <div className="col-md-6">
            <h3 styleName="GlobalScss.Title">My Wishlist</h3>
          </div>
          <div
            className="col-md-6"
            styleName="ManageWishlistScss.WishlistHeader_dropdown_center"
          >
            <div styleName="ManageWishlistScss.WishlistHeader_dropdown_div">
              <select
                styleName="ManageWishlistScss.WishlistHeader_dropdown"
                //{currentLocaleSelection.localeCode}
                // onChange={(e) => setLocale(e.target.value)}
              >
                <option value="Recently added">Recently added</option>
              </select>
            </div>
          </div>
        </div>
        {isEmpty(orderItem) ? (
          <div styleName="GlobalScss.Text_below_header">
            <p>You do not have any items in your wishlist.</p>
          </div>
        ) : (
          <div>
            <ResultList results={orderItem} />
            {/*orderItem.filter(o => !o.parentOrderItemId).map(orderItem => (
                            <div styleName='ManageWishlistScss.WishlistItem' className='col-md-6'>
                                <ResultListItem
                                    key={orderItem.id}
                                    addItemToCart={this.props.addItem}
                                    addToCart={this.props.addToCart}
                                    moveItemToCart={this.props.moveItemToCart}
                                    removeItemFromWishlist={this.props.removeItemFromWishlist}
                                    updateQuantityInWishlist={this.props.updateQuantityInWishlist}
                                    {...orderItem} />
                            </div>
                            */
            /*
                            <WishlistItem
                                key={orderItem.id}
                                addItemToCart={this.props.addItem}
                                addToCart={this.props.addToCart}
                                moveItemToCart={this.props.moveItemToCart}
                                removeItemFromWishlist={this.props.removeItemFromWishlist}
                                updateQuantityInWishlist={this.props.updateQuantityInWishlist}
                                {...orderItem} />
                                
                                

                        ))*/}
          </div>
        )}
      </div>
    );
  }
}

const fetchMissingProductsIfNeeded = (props) => {
  const { orderItem } = props;
  if (orderItem) {
    const missingProducts = orderItem
      .filter((o) => !o.parentOrderItemId && !o.product)
      .map((o) => o.productId);
    if (!isEmpty(missingProducts)) {
      props.fetchProductBatch(missingProducts);
    }
  }
};

const mapStateToProps = (state, props) => {
  return {
    ...getWishlist(state),
    anonymous: isAnonymous(state),
  };
};

const dispatchResolve = (resolver, props) => {
  if (!props.anonymous) {
    resolver.resolve(props.fetchWishlist, true);
    resolver.resolve(fetchMissingProductsIfNeeded, props);
  }
};

export default connect(mapStateToProps, {
  fetchProductBatch,
  fetchWishlist,
  moveItemToCart,
  moveListToCart,
  removeItemFromWishlist,
  updateQuantityInWishlist,
  addToCart,
})(resolve(dispatchResolve)(ManageWishlist));

/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Image, Text } from "@aws-amplify/ui-react";
import Instagramicon from "./Instagramicon";
import Facebookicon from "./Facebookicon";
import LinkedInicon from "./LinkedInicon";
import Whatsappicon from "./Whatsappicon";
import TextLink from "./TextLink";
export default function Footer(props) {
  const {
    logoSrc,
    revolutLogoSrc,
    onLinkedinIconClick,
    onInstagramIconClick,
    onFacebookIconClick,
    onWhatsappIconClick,
    mastercardLogoSrc,
    applePayLogoSrc,
    visaLogoSrc,
    gPayLogoSrc,
    overrides,
    ...rest
  } = props;
  return (
    <Flex
      gap="18px"
      direction="column"
      width="1440px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="64px 80px 64px 80px"
      backgroundColor="rgba(253,252,247,1)"
      {...getOverrideProps(overrides, "Footer")}
      {...rest}
    >
      <Flex
        gap="64px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="space-between"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="24px 0px 24px 0px"
        {...getOverrideProps(overrides, "FooterContent")}
      >
        <Flex
          gap="8px"
          direction="column"
          width="180px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "socials")}
        >
          <Image
            width="100px"
            height="87.91px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src={logoSrc}
            {...getOverrideProps(overrides, "Image40711161")}
          ></Image>
          <Text
            fontFamily="Montserrat"
            fontSize="16px"
            fontWeight="700"
            color="rgba(21,71,38,1)"
            lineHeight="20px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="185px"
            height="19px"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Eat well, Live Vibrant."
            {...getOverrideProps(overrides, "Eat well, Live Vibrant.")}
          ></Text>
          <Flex
            gap="16px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="8px 8px 8px 0px"
            {...getOverrideProps(overrides, "socialmedia")}
          >
            <Instagramicon
              width="24px"
              height="24px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              onClick={onInstagramIconClick}
              {...getOverrideProps(overrides, "Instagramicon")}
            ></Instagramicon>
            <Facebookicon
              width="24px"
              height="24px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              onClick={onFacebookIconClick}
              {...getOverrideProps(overrides, "Facebookicon")}
            ></Facebookicon>
            <LinkedInicon
              width="24px"
              height="24px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              onClick={onLinkedinIconClick}
              {...getOverrideProps(overrides, "LinkedInicon")}
            ></LinkedInicon>
            <Whatsappicon
              width="24px"
              height="24px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              onClick={onWhatsappIconClick}
              {...getOverrideProps(overrides, "Whatsappicon")}
            ></Whatsappicon>
          </Flex>
        </Flex>
        <Flex
          gap="24px"
          direction="column"
          width="121px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "aboutus")}
        >
          <Text
            fontFamily="Montserrat"
            fontSize="16px"
            fontWeight="700"
            color="rgba(21,71,38,1)"
            lineHeight="20px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="ABOUT US"
            {...getOverrideProps(overrides, "ABOUT US")}
          ></Text>
          <Flex
            gap="8px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 445")}
          >
            <TextLink
              display="flex"
              gap="4px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              state="Default"
              label="Home"
              href="/"
              {...getOverrideProps(overrides, "TextLink4470609")}
            ></TextLink>
            <TextLink
              display="flex"
              gap="4px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              state="Default"
              label="Shop All"
              href="/shop"
              {...getOverrideProps(overrides, "TextLink4470615")}
            ></TextLink>
            <TextLink
              display="flex"
              gap="4px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              state="Default"
              label="About Us"
              href="/#aboutUs"
              {...getOverrideProps(overrides, "TextLink4470621")}
            ></TextLink>
            <TextLink
              display="flex"
              gap="4px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              state="Default"
              label="Bulk Orders"
              href="/bulk-orders"
              {...getOverrideProps(overrides, "TextLink4470627")}
            ></TextLink>
          </Flex>
        </Flex>
        <Flex
          gap="24px"
          direction="column"
          width="180px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "contact&help")}
        >
          <Text
            fontFamily="Montserrat"
            fontSize="16px"
            fontWeight="700"
            color="rgba(21,71,38,1)"
            lineHeight="20px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="CONTACT & HELP"
            {...getOverrideProps(overrides, "CONTACT & HELP")}
          ></Text>
          <Flex
            gap="8px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 443")}
          >
            <TextLink
              display="flex"
              gap="4px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              state="Default"
              label="FAQs"
              href="/faq"
              {...getOverrideProps(overrides, "TextLink4470651")}
            ></TextLink>
            <TextLink
              display="flex"
              gap="4px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              state="Default"
              label="Contact Us"
              href="/contactus"
              {...getOverrideProps(overrides, "TextLink4470645")}
            ></TextLink>
            <TextLink
              display="flex"
              gap="4px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              state="Default"
              label="Chat on Whatsapp"
              href="https://api.whatsapp.com/send?phone=353899569854"
              target="_blank"
              {...getOverrideProps(overrides, "TextLink4470639")}
            ></TextLink>
            <TextLink
              display="flex"
              gap="4px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              state="Default"
              label="Terms & Conditions"
              href="/terms"
              {...getOverrideProps(overrides, "TextLink4470633")}
            ></TextLink>
          </Flex>
        </Flex>
        <Flex
          gap="24px"
          direction="column"
          width="134px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "legal")}
        >
          <Text
            fontFamily="Montserrat"
            fontSize="16px"
            fontWeight="700"
            color="rgba(21,71,38,1)"
            lineHeight="20px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="LEGAL"
            {...getOverrideProps(overrides, "LEGAL")}
          ></Text>
          <Flex
            gap="8px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 451")}
          >
            <TextLink
              display="flex"
              gap="4px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              state="Default"
              label="Privacy Policy"
              href="/privacy"
              {...getOverrideProps(overrides, "TextLink4470669")}
            ></TextLink>
            <TextLink
              display="flex"
              gap="4px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              state="Default"
              label="Cookie Policy"
              href="/contactus"
              {...getOverrideProps(overrides, "TextLink4470663")}
            ></TextLink>
            <TextLink
              display="flex"
              gap="4px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              state="Default"
              label="Shipping & Refund Policy"
              href="/shipping-refund"
              {...getOverrideProps(overrides, "TextLink4470657")}
            ></TextLink>
          </Flex>
        </Flex>
        <Flex
          gap="24px"
          direction="column"
          width="139px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "youraccount")}
        >
          <Text
            fontFamily="Montserrat"
            fontSize="16px"
            fontWeight="700"
            color="rgba(21,71,38,1)"
            lineHeight="20px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="YOUR ACCOUNT"
            {...getOverrideProps(overrides, "YOUR ACCOUNT")}
          ></Text>
          <Flex
            gap="8px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 447")}
          >
            <TextLink
              display="flex"
              gap="4px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              state="Default"
              label="Login"
              href="/login"
              {...getOverrideProps(overrides, "TextLink4470681")}
            ></TextLink>
            <TextLink
              display="flex"
              gap="4px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              state="Default"
              label="Register"
              href="/register"
              {...getOverrideProps(overrides, "TextLink4470675")}
            ></TextLink>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="16px"
        direction="row"
        width="420px"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="8px 8px 8px 8px"
        {...getOverrideProps(overrides, "Payment method icons")}
      >
        <Image
          width="72px"
          height="13px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src={revolutLogoSrc}
          {...getOverrideProps(overrides, "Image46181981")}
        ></Image>
        <Image
          width="46px"
          height="33px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src={mastercardLogoSrc}
          {...getOverrideProps(overrides, "Image46181980")}
        ></Image>
        <Image
          width="31px"
          height="21px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src={applePayLogoSrc}
          {...getOverrideProps(overrides, "Image46181979")}
        ></Image>
        <Image
          width="45px"
          height="31px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src={gPayLogoSrc}
          {...getOverrideProps(overrides, "Image46181978")}
        ></Image>
        <Image
          width="46px"
          height="29px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src={visaLogoSrc}
          {...getOverrideProps(overrides, "Image46181977")}
        ></Image>
      </Flex>
      <Flex
        gap="8px"
        direction="row"
        width="455px"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="8px 8px 8px 8px"
        {...getOverrideProps(overrides, "Copyrights")}
      >
        <Text
          fontFamily="Montserrat"
          fontSize="14px"
          fontWeight="400"
          color="rgba(89,84,84,1)"
          lineHeight="21px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="© 2024 Reddot Food Limited. &#xA;All rights reserved."
          {...getOverrideProps(overrides, "\u00A9 2024 Reddot Food Limited")}
        ></Text>
      </Flex>
    </Flex>
  );
}

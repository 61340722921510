import React, { useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import ProductOptionsStyle from './ProductOptions.module.scss';
import { format } from 'layout/util/moneyUtils';
import classNames from 'classnames';

const ProductOptions = ({
  additionalSkus,
  selectedSku,
  onSkuChange,
  isInProductCard,
}) => {
  if (isEmpty(additionalSkus)) {
    return false;
  }

  const updateSku = (option) => {
    selectedSku = option;
    onSkuChange(selectedSku);
  };

  return (
    <div
      className={classNames(
        ProductOptionsStyle.Product_option,
        !isInProductCard && additionalSkus && additionalSkus.length == 1
          ? ProductOptionsStyle.Product_option_one_sku_not_card
          : ''
      )}
    >
      {additionalSkus.map((option, index) => {
        return (
          <div
            key={option.id}
            className={classNames(ProductOptionsStyle.Product_option_box)}
          >
            <button
              disabled={!option.available}
              className={classNames(
                selectedSku && selectedSku.id === option.id
                  ? ProductOptionsStyle.Button__selected
                  : ProductOptionsStyle.Button__not_selected,
                isInProductCard ? ProductOptionsStyle.Button_Font_Small : ''
              )}
              onClick={() => {
                updateSku(option);
              }}
            >
              {`${format(option.retailPrice)}/${option.productOptionsValue}`}
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default ProductOptions;

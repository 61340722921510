import React, { Component, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchPageByUrl } from '../common/actions/pageActions';
import GlobalScss from '../../layout/style/common.scss';
import ContactUsStyle from './ContactUs.module.scss';
import CheckoutField from '../../checkout/components/CheckoutField/CheckoutField';
import { Field, Form, reduxForm, SubmissionError } from 'redux-form';
import Button from 'material-kit/components/Button';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
//import emailjs from '@emailjs/browser';

class ContactUs extends Component {
  state = {
    emailSuccess: false,
  };

  static propTypes = {
    pageData: PropTypes.object,
    fetchPageByUrl: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { match, fetchPageByUrl } = this.props;
    fetchPageByUrl(match.url);
  }

  _onSubmitForm = (form) => {
    emailjs.init('Fty6c-ENJZXPuJMO0');
    emailjs
      .send('service_20rmez9', 'template_6r5iado', {
        user_email: form.user_email,
        user_firstName: form.user_firstName,
        user_lastName: form.user_lastName,
        message: form.message,
        form,
      })
      .then((res) => {
        console.log('Email successfully sent!');
        this.setState({ emailSuccess: true });
        setTimeout(() => {
          this.setState({ emailSuccess: false });
        }, 5000);
      })
      .catch((err) => console.log(error));
  };

  render() {
    const { emailSuccess } = this.state;
    const { pageData, page, pageFields } = this.props;

    /*
            if (!pageData || pageData.isFetching) {
                return <div>Loading...</div>;
            }
    
            if (pageData.error) {
                return <div>Error loading page.</div>;
            }
            */

    return (
      <div className={GlobalScss.Page}>
        <Helmet titleTemplate="%s | Reddot Food Store">
          <title>Contact Us</title>
          <meta name="description" content="Contact us" />
          <link rel="canonical" href="/contactus" />
        </Helmet>
        <div
          className={classNames(
            GlobalScss.Container_margin_bottom,
            'container'
          )}
        >
          <div
            className={classNames(
              GlobalScss.Page_header,
              GlobalScss.Page_header__padding_left_background
            )}
          >
            <h1 className={GlobalScss.Title}>Contact us</h1>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6">
            <div className="panel panel-border panel-default">
              <div className="panel-body">
                <p>
                  Our dedicated team is eager to assist you with any enquiries,
                  suggestions, or feedback you may have and we aim to respond
                  promptly.
                </p>
              </div>
              <div className={ContactUsStyle.addressAndWatsup_div}>
                <div className={ContactUsStyle.addressAndWatsup}>
                  <div className={ContactUsStyle.imgDiv}>
                    <img src="/static/img/whatsapp.svg" />
                  </div>
                  <div>
                    <h3>Customer care number</h3>
                    <p>+353 89 956 9854</p>

                    <a
                      className={ContactUsStyle.WhatssapButton}
                      // component="a"
                      href="https://api.whatsapp.com/send?phone=353899569854"
                      target="_blank"
                      lg
                      simple
                    >
                      Connect On WhatsApp
                      <img src="/static/img/secondary_CTA_icon.svg" />
                    </a>
                  </div>
                </div>
                <div className={ContactUsStyle.addressAndWatsup}>
                  <div className={ContactUsStyle.imgDiv}>
                    <img src="/static/img/placeholder.svg" />
                  </div>
                  <div>
                    <h3>Indian operation</h3>
                    <p>
                      fssai number: 13623034000316 <br></br>
                      7-88/P1/118A Andal Nilayam <br></br>
                      Aparnas palm Grove <br></br> Kompally <br></br>
                      Hyderabad <br></br>
                      India <br></br>
                      500014 <br></br>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={ContactUsStyle.imgMask}>
              <img
                className={ContactUsStyle.building_image}
                src={
                  '/static/img/low-angle-tall-building-with-many-windows.jpg'
                }
              />
            </div>
          </div>

          <div
            className={classNames(
              GlobalScss.ContactUsForm,
              'col-sm-6 col-md-6 col-lg-6'
            )}
          >
            <div
              className={classNames(
                GlobalScss.Account_border_box,
                GlobalScss.Account_border_box_margin
              )}
            >
              <ContactUsForm
                onSubmit={this._onSubmitForm}
                emailSuccess={emailSuccess}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const FormValidators = {
  required: (value) => (value ? undefined : 'Required'),
};

const ContactUsForm = reduxForm({
  enableReinitialize: true,
  form: 'ContactUsForm',
})(({ error, handleSubmit, submitSucceeded, emailSuccess }) => (
  <Form onSubmit={handleSubmit} className={GlobalScss.Contact_us_form}>
    <h4 className={classNames(ContactUsStyle.Form_title_font, 'title')}>
      Get in touch
    </h4>

    {emailSuccess && (
      <span className="text-success">Successfully email sent!</span>
    )}

    {error && <span className="text-danger">{error}</span>}

    <Field
      addon="email"
      component={CheckoutField}
      inputGroup={false}
      label="Email"
      placeholder="Enter your email"
      name="user_email"
      type="email"
      validate={FormValidators.required}
    />
    <Field
      component={CheckoutField}
      inputGroup={false}
      label="First Name"
      name="user_firstName"
      placeholder="Enter your first name"
      type="text"
      validate={FormValidators.required}
    />
    <Field
      component={CheckoutField}
      inputGroup={false}
      label="Last Name"
      name="user_lastName"
      type="text"
      placeholder="Enter your last name"
      validate={FormValidators.required}
    />
    <Field
      component={CheckoutField}
      inputGroup={false}
      label="Message"
      name="message"
      type="textArea"
      placeholder="Enter your message here"
      validate={FormValidators.required}
    />

    <Button
      type="submit"
      className={GlobalScss.Contact_us_submit_button}
      primary
    >
      Submit
    </Button>
  </Form>
));

const mapStateToProps = (state, ownProps) => ({
  pageData: state.page[ownProps.match.url],
});

const mapDispatchToProps = {
  fetchPageByUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);

/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function CustomBadges(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: { label: {}, CustomBadges: {} },
      variantValues: { type: "Sale" },
    },
    {
      overrides: {
        label: { color: "rgba(149,4,4,1)", children: "NEW" },
        CustomBadges: {
          padding: "5px 7px 5px 7px",
          backgroundColor: "rgba(245,188,188,1)",
          border: "1px SOLID rgba(149,4,4,1)",
        },
      },
      variantValues: { type: "New" },
    },
    {
      overrides: {
        label: { color: "rgba(149,4,4,1)", children: "BEST SELLER" },
        CustomBadges: {
          padding: "5px 7px 5px 7px",
          backgroundColor: "rgba(245,188,188,1)",
          border: "1px SOLID rgba(149,4,4,1)",
        },
      },
      variantValues: { type: "Bestseller" },
    },
    {
      overrides: {
        label: { color: "rgba(89,84,84,1)", children: "BUNDLE" },
        CustomBadges: {
          padding: "5px 7px 5px 7px",
          backgroundColor: "rgba(255,219,168,1)",
          border: "1px SOLID rgba(191,128,64,1)",
        },
      },
      variantValues: { type: "Bundle" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="10px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      borderRadius="32px"
      padding="6px 8px 6px 8px"
      backgroundColor="rgba(191,64,64,1)"
      display="flex"
      {...getOverrideProps(overrides, "CustomBadges")}
      {...rest}
    >
      <Text
        fontFamily="Inter"
        fontSize="12px"
        fontWeight="600"
        color="rgba(253,252,247,1)"
        lineHeight="12px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="ON SALE"
        {...getOverrideProps(overrides, "label")}
      ></Text>
    </Flex>
  );
}

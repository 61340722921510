/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Divider, Flex, Text } from "@aws-amplify/ui-react";
import Check from "./Check";
export default function CounryselectorMenuitem(props) {
  const {
    countryLabel,
    currencyLabel,
    onClick,
    flagplaceholder,
    showCheck,
    overrides: overridesProp,
    ...rest
  } = props;
  const variants = [
    {
      overrides: {
        flagplaceholder: {},
        label: {},
        currency: {},
        link: {},
        Divider: {},
        Content: {},
        check: {},
        CounryselectorMenuitem: {},
      },
      variantValues: { state: "Default" },
    },
    {
      overrides: {
        flagplaceholder: {},
        label: {},
        currency: {},
        link: {},
        Divider: {},
        Content: {},
        check: { display: "block" },
        CounryselectorMenuitem: {},
      },
      variantValues: { state: "Active" },
    },
    {
      overrides: {
        flagplaceholder: {},
        label: { color: "rgba(255,255,255,1)" },
        currency: { color: "rgba(255,255,255,1)" },
        link: { backgroundColor: "rgba(168,104,0,1)" },
        Divider: {},
        Content: {},
        check: {},
        CounryselectorMenuitem: {},
      },
      variantValues: { state: "Hover" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="16px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      onClick={onClick}
      {...getOverrideProps(overrides, "CounryselectorMenuitem")}
      {...rest}
    >
      <Flex
        gap="8px"
        direction="column"
        width="246px"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Content")}
      >
        <Flex
          gap="8px"
          direction="row"
          width="unset"
          height="24px"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 16px 0px 16px"
          display="flex"
          {...getOverrideProps(overrides, "link")}
        >
          <Flex
            gap="0"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            borderRadius="100px"
            padding="6px 6px 6px 6px"
            display="flex"
            children={flagplaceholder}
            {...getOverrideProps(overrides, "flagplaceholder")}
          ></Flex>
          <Text
            fontFamily="Montserrat"
            fontSize="14px"
            fontWeight="400"
            color="rgba(106,51,22,1)"
            lineHeight="21px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={countryLabel}
            {...getOverrideProps(overrides, "label")}
          ></Text>
          <Text
            fontFamily="Montserrat"
            fontSize="14px"
            fontWeight="400"
            color="rgba(89,84,84,1)"
            lineHeight="21px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={currencyLabel}
            {...getOverrideProps(overrides, "currency")}
          ></Text>
        </Flex>
        <Divider
          width="unset"
          height="1px"
          shrink="0"
          alignSelf="stretch"
          size="small"
          orientation="horizontal"
          {...getOverrideProps(overrides, "Divider")}
        ></Divider>
      </Flex>
      <Check
        width="24px"
        height="24px"
        display={showCheck == true ? "block" : "none"}
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "check")}
      ></Check>
    </Flex>
  );
}

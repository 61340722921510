/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Text } from "@aws-amplify/ui-react";
import Dropdown from "./Dropdown";
import Dropdownclose from "./Dropdownclose";
export default function Submenu(props) {
  const { label, onClick, state, overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        label: {},
        Dropdown: {},
        icon44191254: {},
        Dropdownclose: {},
        icon44191256: {},
        Submenu: {},
      },
      variantValues: { state: "Collapsed" },
    },
    {
      overrides: {
        label: {},
        Dropdown: {},
        icon44191254: { display: "none" },
        Dropdownclose: {},
        icon44191256: { display: "flex" },
        Submenu: {},
      },
      variantValues: { state: "Default" },
    },
    {
      overrides: {
        label: { color: "rgba(255,255,255,1)" },
        Dropdown: {},
        icon44191254: { display: "none" },
        Dropdownclose: {},
        icon44191256: {},
        Submenu: {
          padding: "4px 16px 4px 16px",
          backgroundColor: "rgba(168,104,0,1)",
        },
      },
      variantValues: { state: "Hover" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="8px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      onClick={onClick}
      {...getOverrideProps(overrides, "Submenu")}
      {...rest}
    >
      <Text
        fontFamily="Caveat Brush"
        fontSize="16px"
        fontWeight="400"
        color="rgba(106,51,22,1)"
        lineHeight="24px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={label}
        {...getOverrideProps(overrides, "label")}
      ></Text>
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        borderRadius="4px"
        padding="6px 6px 6px 6px"
        display="flex"
        {...getOverrideProps(overrides, "icon44191254")}
      >
        <Dropdown
          width="24px"
          height="24px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Dropdown")}
        ></Dropdown>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        borderRadius="4px"
        padding="6px 6px 6px 6px"
        display="none"
        {...getOverrideProps(overrides, "icon44191256")}
      >
        <Dropdownclose
          width="24px"
          height="24px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Dropdownclose")}
        ></Dropdownclose>
      </Flex>
    </Flex>
  );
}

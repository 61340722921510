/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, Flex, TextAreaField } from "@aws-amplify/ui-react";
export default function Notepopup(props) {
  const {
    value = '"How Can We Help you?"',
    onTextAreaChange,
    onSaveButtonClicked,
    overrides,
    ...rest
  } = props;
  return (
    <Flex
      gap="8px"
      direction="column"
      width="648px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="64px 0px 64px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "Notepopup")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 492")}
      >
        <TextAreaField
          width="unset"
          height="unset"
          label="Add Order Note"
          placeholder="How Can We Help you?"
          padding="16px 32px 16px 32px"
          shrink="0"
          alignSelf="stretch"
          backgroundColor="rgba(255,255,255,1)"
          size="default"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          value={value}
          onChange={onTextAreaChange}
          {...getOverrideProps(overrides, "TextAreaField")}
        ></TextAreaField>
        <Flex
          gap="8px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 32px 0px 32px"
          {...getOverrideProps(overrides, "Frame 494")}
        >
          <Button
            width="unset"
            height="unset"
            shrink="0"
            size="default"
            isDisabled={false}
            variation="primary"
            children="SAVE"
            onClick={onSaveButtonClicked}
            {...getOverrideProps(overrides, "Button")}
          ></Button>
        </Flex>
      </Flex>
    </Flex>
  );
}

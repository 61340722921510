/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Text, View } from "@aws-amplify/ui-react";
export default function Primarymenu(props) {
  const { title, slot, overrides, ...rest } = props;
  return (
    <Flex
      gap="16px"
      direction="row"
      width="320px"
      height="574px"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="24px 16px 24px 16px"
      backgroundColor="rgba(254,234,210,1)"
      {...getOverrideProps(overrides, "Primarymenu")}
      {...rest}
    >
      <Flex
        gap="32px"
        direction="column"
        width="283px"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 32px 0px 32px"
        {...getOverrideProps(overrides, "Sidebar")}
      >
        <Flex
          gap="16px"
          direction="column"
          width="207px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Section")}
        >
          <Text
            fontFamily="Montserrat"
            fontSize="14px"
            fontWeight="700"
            color="rgba(61,12,0,1)"
            lineHeight="21px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={title}
            {...getOverrideProps(overrides, "label")}
          ></Text>
          <View
            width="116px"
            height="335px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            children={slot}
            {...getOverrideProps(overrides, "slot")}
          ></View>
        </Flex>
      </Flex>
    </Flex>
  );
}

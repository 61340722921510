/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Text } from "@aws-amplify/ui-react";
import Chevronforward from "./Chevronforward";
export default function Picker(props) {
  const { countryLabel, currencyLabel, flagplaceholder, overrides, ...rest } =
    props;
  return (
    <Flex
      gap="8px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="0px 16px 0px 16px"
      {...getOverrideProps(overrides, "Picker")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        borderRadius="100px"
        padding="6px 6px 6px 6px"
        children={flagplaceholder}
        {...getOverrideProps(overrides, "flagplaceholder")}
      ></Flex>
      <Text
        fontFamily="Montserrat"
        fontSize="14px"
        fontWeight="400"
        color="rgba(106,51,22,1)"
        lineHeight="21px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={countryLabel}
        {...getOverrideProps(overrides, "label4419991")}
      ></Text>
      <Text
        fontFamily="Montserrat"
        fontSize="14px"
        fontWeight="400"
        color="rgba(89,84,84,1)"
        lineHeight="21px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={currencyLabel}
        {...getOverrideProps(overrides, "label4419992")}
      ></Text>
      <Flex
        gap="8px"
        direction="row"
        width="24px"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "icon")}
      >
        <Chevronforward
          width="unset"
          height="24px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "chevron_forward")}
        ></Chevronforward>
      </Flex>
    </Flex>
  );
}

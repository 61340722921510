/*
 * #%L
 * React Site Starter
 * %%
 * Copyright (C) 2009 - 2017 Broadleaf Commerce
 * %%
 * Broadleaf Commerce React Starter
 *
 * Written in 2017 by Broadleaf Commerce info@broadleafcommerce.com
 *
 * To the extent possible under law, the author(s) have dedicated all copyright and related and neighboring rights to this software to the public domain worldwide. This software is distributed without any warranty.
 * You should have received a copy of the CC0 Public Domain Dedication along with this software. If not, see <http://creativecommons.org/publicdomain/zero/1.0/>.
 *
 * Please Note - The scope of CC0 Public Domain Dedication extends to Broadleaf Commerce React Starter demo application alone. Linked libraries (including all Broadleaf Commerce Framework libraries) are subject to their respective licenses, including the requirements and restrictions specified therein.
 * #L%
 */
import React, { PureComponent } from 'react';
import TestimonialStyles from './Testimonial.module.scss';
import classNames from 'classnames';
class Testimonial extends PureComponent {
  state = {};

  render() {
    const { name, location, img, bodyP1, bodyP2, srcSet, isActive } =
      this.props;

    return (
      <div>
        <div className={TestimonialStyles.TestimonialBg}>
          <div
            className={classNames({
              [TestimonialStyles.TestimonialParent]: true,
              [TestimonialStyles.active]: isActive,
            })}
          >
            {isActive && (
              <div className={TestimonialStyles.QuotationMarks}>“</div>
            )}
            {!isActive && (
              <div className={TestimonialStyles.QuotationMarksSmallScreen}>
                “
              </div>
            )}
            <div className={TestimonialStyles.TestimonialPadding}>
              <div className={TestimonialStyles.TestimonialText}>
                <p>{bodyP1}</p>
                {bodyP2 && <p>{bodyP2}</p>}
                <div className={TestimonialStyles.QuotationMarksMuted}>“</div>
              </div>
              <div className={TestimonialStyles.TestimonialProfileInfo}>
                <img
                  srcSet={srcSet}
                  sizes="
                        (max-width: 600px) 480px,
                        800px
                      "
                  className={TestimonialStyles.ProfileImg}
                />
                <div className={TestimonialStyles.ProfileText}>
                  <div className={TestimonialStyles.ProfileName}>{name}</div>
                  <div className={TestimonialStyles.ProfileLocation}>
                    {location}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/*const mapStateToProps = (state, props) => {
    return {
        inCart: inCart(state, props),
        previewing: isPreviewing(state),
        showSearchScore: state.preview.showSearchScore,
    }
}

export default connect(mapStateToProps)(Testimonial)
*/

export default Testimonial;

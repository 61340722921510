/*
 * #%L
 * React Site Starter
 * %%
 * Copyright (C) 2009 - 2017 Broadleaf Commerce
 * %%
 * Broadleaf Commerce React Starter
 *
 * Written in 2017 by Broadleaf Commerce info@broadleafcommerce.com
 *
 * To the extent possible under law, the author(s) have dedicated all copyright and related and neighboring rights to this software to the public domain worldwide. This software is distributed without any warranty.
 * You should have received a copy of the CC0 Public Domain Dedication along with this software. If not, see <http://creativecommons.org/publicdomain/zero/1.0/>.
 *
 * Please Note - The scope of CC0 Public Domain Dedication extends to Broadleaf Commerce React Starter demo application alone. Linked libraries (including all Broadleaf Commerce Framework libraries) are subject to their respective licenses, including the requirements and restrictions specified therein.
 * #L%
 */
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addToCart } from 'cart/actions';
import { removeItemFromWishlist } from 'account/actions';
import { inCart } from 'cart/selectors';
import { isPreviewing } from 'preview/selectors';
import Button from 'material-kit/components/Button';
import WishlistButton from 'catalog/product/components/WishlistButton';
import classNames from 'classnames';
import { format } from 'layout/util/moneyUtils';
import isEmpty from 'lodash/isEmpty';
import union from 'lodash/union';
import ResultListItemStyle from './ResultListItem.module.scss';
import SkuWithQuantityPicker from 'catalog/product/components/Product/SkuWithQuantityPicker';
import { trackEvent } from '../../../../cart/events/TrackEvent';
import { EVENT_NAME } from '../../../../cart/events/EventName';

class ResultListItem extends PureComponent {
  state = {
    quantity: 1,
    sku: null,
    selectedSku: null,
  };

  _onUpdateQuantity = (newQuantity) => {
    if (newQuantity < 1) {
    } else {
      this.setState({
        quantity: newQuantity,
      });
    }
  };

  _onUpdateSku = (newSku) => {
    this.setState({
      sku: newSku.id,
      selectedSku: newSku,
    });
    if (
      newSku.productQuantityAvailable &&
      newSku.productQuantityAvailable < this.state.quantity
    ) {
      this.setState({
        quantity: newSku.productQuantityAvailable,
      });
    }
  };

  _onSelectAddress = (id) => {
    this.setState({
      selectedAddressId: id,
    });
  };

  _renderAddToCartButton = (quantity) => {
    const {
      addToCart,
      defaultSku,
      hasChildProductAddOns,
      id,
      inCart,
      productOption,
      setShowToaster,
    } = this.props;

    if (inCart) {
      return (
        <Button
          component={Link}
          componentProps={{
            to: '/cart',
          }}
          round
          success
          primary
          className={ResultListItemStyle.ResultListItem_add_to_cart_button}
        ></Button>
      );
    }

    if (
      !isEmpty(productOption) ||
      hasChildProductAddOns ||
      !defaultSku.available
    ) {
      return false;
    }

    const handleAddToCart = (skuId, productId, quantity) => {
      setShowToaster(true);
      addToCart({
        skuId,
        productId,
        quantity,
      });
    };

    return (
      <Button
        primary
        className={ResultListItemStyle.ResultListItem_add_to_cart_button}
        onClick={() => handleAddToCart(this.state.sku, id, this.state.quantity)}
        round
      >
        Add to cart
      </Button>
    );
  };

  _renderPromotionMessages = () => {
    const { promotionMessages } = this.props;
    if (!promotionMessages) {
      return undefined;
    }

    const { EVERYWHERE = [], BROWSE = [] } = promotionMessages;

    return union(EVERYWHERE, BROWSE).map((promotionMessage, index) => (
      <div key={index}>{promotionMessage.message}</div>
    ));
  };

  render() {
    const {
      className,
      defaultSku,
      id,
      inCart,
      longDescription,
      name,
      primaryMedia,
      previewing,
      retailPrice,
      salePrice,
      showSearchScore,
      style,
      url,
      additionalSkus,
      minPrice,
      maxPrice,
      orderId,
      productId,
      productUrl,
      addToCart,
      bundleItemsSalePrice,
      bundleItemsRetailPrice,
      setShowToaster,
    } = this.props;

    const handleAddToCart = (skuId, productId, quantity) => {
      setShowToaster(true);
      addToCart({
        skuId,
        productId,
        quantity,
      }).then((response) => {
        if (response.payload.cart) {
          trackEvent(EVENT_NAME.CART_UPDATED, response.payload.cart);
        }
      });
    };

    return (
      <div className={ResultListItemStyle.ResultListItem}>
        {previewing && showSearchScore && !!defaultSku.score && (
          <div>{defaultSku.score.toFixed(3)}</div>
        )}
        {/* Product Image */}

        <article className={ResultListItemStyle.card}>
          <div className={ResultListItemStyle.thumb}>
            <div className={ResultListItemStyle.tags}>
              {(bundleItemsSalePrice || bundleItemsRetailPrice) && (
                <div
                  className={classNames(
                    ResultListItemStyle.tagBox,
                    ResultListItemStyle.bundleTag
                  )}
                >
                  BUNDLE
                </div>
              )}
              {salePrice && (
                <div
                  className={classNames(
                    ResultListItemStyle.tagBox,
                    ResultListItemStyle.bundleTag
                  )}
                >
                  SALE
                </div>
              )}
            </div>
            <Link to={orderId === undefined ? url : productUrl}>
              <img
                className={ResultListItemStyle.productImage}
                src={
                  primaryMedia ? primaryMedia.url : '/static/img/noPhoto.png'
                }
                alt={primaryMedia ? primaryMedia.altText : 'No photo available'}
              />
            </Link>
          </div>
          <div
            className={classNames(ResultListItemStyle.infos, {
              [ResultListItemStyle.four_skus]:
                additionalSkus && additionalSkus.length >= 3,
              [ResultListItemStyle.two_skus]: !(
                additionalSkus && additionalSkus.length >= 3
              ),
            })}
          >
            <div>
              <div
                className={classNames(
                  'col-xs-10 col-sm-10',
                  ResultListItemStyle.Item_name_div
                )}
              >
                <h2 className={ResultListItemStyle.Item_name}>
                  <Link
                    to={orderId === undefined ? url : productUrl}
                    className={ResultListItemStyle.ResultListItem__name}
                  >
                    {name}
                  </Link>
                </h2>
              </div>
              <div className="col-xs-2 col-sm-2">
                {!orderId && <WishlistButton id={defaultSku.id} />}
                {orderId && (
                  <a
                    onClick={() =>
                      this.props.removeItemFromWishlist({ itemId: id })
                    }
                  >
                    <img
                      className={ResultListItemStyle.ResultListItem_icon}
                      src="/static/img/trash.svg"
                    />
                  </a>
                )}
              </div>
            </div>
            <div
              className={ResultListItemStyle.ResultListItem_price_description}
            >
              <div
                className={ResultListItemStyle.ResultListItem_description}
                dangerouslySetInnerHTML={{ __html: longDescription }}
              ></div>
              <div className={ResultListItemStyle.ResultListItem_price}>
                <Link
                  to={orderId === undefined ? url : productUrl}
                  className={ResultListItemStyle.ResultListItem_icon_secondary}
                >
                  <img src="/static/img/secondaryArrowIcon.svg" />
                </Link>
                <p>
                  {minPrice != null
                    ? `${format(minPrice)}-${format(maxPrice)}`
                    : format(retailPrice)}
                </p>
              </div>
            </div>

            {!defaultSku.available && (
              <div className={ResultListItemStyle.ResultListItem_out_of_stock}>
                <h2
                  className={classNames(
                    'card-title',
                    ResultListItemStyle.ResultListItem__options_quantity
                  )}
                >
                  Out Of Stock
                </h2>
              </div>
            )}

            {defaultSku.available && (
              <div
                className={classNames(
                  'col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12',
                  ResultListItemStyle.ResultListItem__options_quantity
                )}
              >
                <SkuWithQuantityPicker
                  defaultSku={defaultSku}
                  additionalSkus={additionalSkus}
                  onSkuChange={this._onUpdateSku}
                  selectedSku={this.state.selectedSku}
                  quantity={this.state.quantity}
                  updateQuantity={this._onUpdateQuantity}
                  isInProductCard={true}
                ></SkuWithQuantityPicker>
              </div>
            )}
            {/*defaultSku.available && (
              <div
                className={classNames(
                  'col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12',
                  ResultListItemStyle.ResultListItem__options_quantity
                )}
              >
                <div
                  className={classNames(
                    ResultListItemStyle.ResultListItem__product_options,
                    {
                      [ResultListItemStyle.ResultListItem__product_options_four_skus]:
                        additionalSkus && additionalSkus.length >= 3,
                      [ResultListItemStyle.ResultListItem__product_options_two_skus]:
                        !(additionalSkus && additionalSkus.length >= 3),
                    }
                  )}
                >
                  {!isEmpty(additionalSkus) &&
                    additionalSkus.map((child, index) => {
                      const isOdd = additionalSkus.length % 2 !== 0;

                      return (
                        <div
                          key={child.id}
                          className={classNames(
                            'col-lg-6 col-md-6 col-sm-6 col-xs-6 col-12',
                            ResultListItemStyle.ResultListItem__sku_option_div,
                            {
                              [ResultListItemStyle.Custom_last_odd]: isOdd,
                            }
                          )}
                        >
                          <button
                            id={child.id}
                            className={
                              ResultListItemStyle.ResultListItem__sku_option
                            }
                            disabled={child.availability}
                            onClick={() => {
                              updateAmount(child.id, additionalSkus),
                                this._onUpdateSku(child);
                            }}
                          >
                            <span
                              className={
                                ResultListItemStyle.ResultListItem__sku_option_text
                              }
                            >
                              {`${format(child.retailPrice.amount)}/${child.productOptionsValue}`}
                            </span>
                          </button>
                        </div>
                      );
                    })}
                </div>
                <ProductQuantity
                  centered={true}
                  quantity={this.state.quantity}
                  updateQuantity={this._onUpdateQuantity}
                  disabledAdd={isAddToCartDisabled(
                    additionalSkus,
                    defaultSku,
                    this.state.selectedSku,
                    this.state.quantity
                  )}
                />
              </div>
            )/*}

            {/* Item Details */}
            <div
              className={classNames(
                'col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12',
                ResultListItemStyle.ResultListItem_add_to_cart_button_div
              )}
            >
              {/*{getProductOptionDisplayValues(product, orderItemAttributes).map(option => (
                                <div key={option.value}>
                                    {`${option.label}: ${option.value}`}
                                </div>
                                ))}

                            {!isEmpty(bundleItems) && (
                                <div>
                                    Bundled Products:
                                </div>
                            )}
                            {!isEmpty(bundleItems) && bundleItems.map(child => (
                                <div key={child.id}>
                                    {`${child.quantity} x ${child.name} @ ${format(child.priceBeforeAdjustments)}`}
                                </div>
                            ))}
                               */}

              {defaultSku.available && (
                <div>
                  <Button
                    primary
                    className={
                      ResultListItemStyle.ResultListItem_add_to_cart_button
                    }
                    disabled={additionalSkus != null && this.state.sku == null}
                    onClick={() => {
                      const productIdToUse =
                        orderId != undefined ? productId : id;
                      handleAddToCart(
                        this.state.sku,
                        productIdToUse,
                        this.state.quantity
                      );
                    }}
                    round
                  >
                    Add to cart
                  </Button>
                </div>
              )}
            </div>

            {/*
                        <h3 styleName="date">november 2 - 4</h3>
                        <h3 styleName="seats">seats remaining: 2</h3>
                        <p styleName="txt">
                            Join us for our Live Infinity Session in beautiful New York City. This is a 3 day
                            intensive workshop where you'll learn how to become a better version of...
                        </p>
                        <h3 styleName="details">event details</h3>
                        */}
          </div>
        </article>

        {/*defaultSku.available && (
                    <div>
                        <Button
                            primary styleName='ResultListItem_add_to_cart_button'
                            disabled={additionalSkus != null && this.state.sku == null}
                            //onClick={e => addToCart({ skuId: this.state.sku, productId: id, quantity: this.state.quantity })}
                            onClick={e => {
                                const productIdToUse = (orderId != undefined) ? productId : id;
                                addToCart({ skuId: this.state.sku, productId: productIdToUse, quantity: this.state.quantity });
                            }}
                            round>
                            Add to cart
                        </Button>
                    </div>
                )
                        */}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    inCart: inCart(state, props),
    previewing: isPreviewing(state),
    showSearchScore: state.preview.showSearchScore,
  };
};

export default connect(mapStateToProps, { addToCart, removeItemFromWishlist })(
  ResultListItem
);

import React from 'react';
import { Link } from 'react-router-dom';
import { Field, Form, reduxForm } from 'redux-form';
import Button from 'material-kit/components/Button';
import LoginRegisterField from './LoginRegisterField';

const ForgotPasswordForm = ({ handleSubmit, error }) => (
  <Form onSubmit={handleSubmit}>
    <div className="text-center">
      <h3 className="card-title">Forgot password</h3>
      <p>Enter your email address to retrieve your password.</p>
    </div>
    <Field
      addon="email"
      component={LoginRegisterField}
      label="Email"
      name="username"
      type="email"
      validate={validators.required}
    />
    {error && <p className="text-danger">{error}</p>}
    <div className="text-center">
      <button className="btn btn-primary btn-lg" type="submit">
        Get Password
      </button>
    </div>
    <div className="text-center">
      <a className="account" href="/login">
        Login
      </a>
      |
      <a className="account" href="/register">
        Register
      </a>
    </div>
  </Form>
);

const validators = {
  required: (value) => (value ? undefined : 'Required'),
  password: (value) =>
    value && value.length >= 4
      ? undefined
      : 'Password must be at least four characters',
};

export default reduxForm({ form: 'ForgotPasswordForm' })(ForgotPasswordForm);

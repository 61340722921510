/*
 * #%L
 * React Site Starter
 * %%
 * Copyright (C) 2009 - 2017 Broadleaf Commerce
 * %%
 * Broadleaf Commerce React Starter
 *
 * Written in 2017 by Broadleaf Commerce info@broadleafcommerce.com
 *
 * To the extent possible under law, the author(s) have dedicated all copyright and related and neighboring rights to this software to the public domain worldwide. This software is distributed without any warranty.
 * You should have received a copy of the CC0 Public Domain Dedication along with this software. If not, see <http://creativecommons.org/publicdomain/zero/1.0/>.
 *
 * Please Note - The scope of CC0 Public Domain Dedication extends to Broadleaf Commerce React Starter demo application alone. Linked libraries (including all Broadleaf Commerce Framework libraries) are subject to their respective licenses, including the requirements and restrictions specified therein.
 * #L%
 */
import React, { useState, useEffect, useRef } from 'react';
import './TopOptionalInfoWidget.module.scss';
import TopOptionalInfoWidgetStyle from './TopOptionalInfoWidget.module.scss';
import Button from 'material-kit/components/Button';
import request from 'core/util/superagent'; // Import superagent

const TopOptionalInfoWidget = () => {
  const [banners, setBanners] = useState([]); // State to store fetched banners
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(true);
  const [timeLeft, setTimeLeft] = useState(5000); // Track the time left for the next slide
  const intervalRef = useRef(null);
  const startTimeRef = useRef(null); // Store when the interval started

  // const banners = sc.values.htmlContent || [];

  const fetchBanners = async () => {
    try {
      const response = await request.get('/api/optional-banner-info');
      if (response.body && response.body.optionalInfoBanners) {
        setBanners(
          response.body.optionalInfoBanners.map((banner) => banner.info)
        );
      }
    } catch (error) {
      console.error('Error fetching banners:', error);
    }
  };

  const startRotation = (delay) => {
    if (banners.length > 0) {
      clearInterval(intervalRef.current); // Clear any existing interval
      startTimeRef.current = Date.now(); // Track when the interval starts

      intervalRef.current = setInterval(() => {
        handleNextClick();
        setTimeLeft(5000); // Reset the timeLeft for the next slide
      }, delay);
    }
  };

  const stopRotation = () => {
    clearInterval(intervalRef.current); // Stop the interval
    const elapsedTime = Date.now() - startTimeRef.current; // Calculate elapsed time
    setTimeLeft((prevTimeLeft) => prevTimeLeft - elapsedTime); // Adjust time left
  };

  const handleNextClick = () => {
    setFade(false); // Start fade-out animation
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length);
      setFade(true); // Start fade-in animation after content changes
    }, 100); // Duration should match the transition duration
  };

  const handlePreviousClick = () => {
    setFade(false); // Start fade-out animation
    setTimeout(() => {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + banners.length) % banners.length
      );
      setFade(true); // Start fade-in animation after content changes
    }, 100); // Duration should match the transition duration
  };

  useEffect(() => {
    fetchBanners();
  }, []); // Fetch banners only once when the component is mounted

  useEffect(() => {
    if (banners.length > 1) {
      startRotation(timeLeft); // Start the rotation only when banners are loaded
    }
    return () => clearInterval(intervalRef.current); // Clean up on unmount
  }, [banners, currentIndex]); // Rerun when banners or currentIndex change

  // If no banners are available, don't render anything
  /*
  if (banners.length === 0) {
    return null;
  }
    */
  return (
    <section className={TopOptionalInfoWidgetStyle.info_banner}>
      <div>
        {banners.length > 1 && (
          <Button
            onClick={handlePreviousClick}
            className={TopOptionalInfoWidgetStyle.slide_buttons}
            lg
            simple
          >
            <img src="/static/img/left_arrow.svg" />
          </Button>
        )}
        <div
          className={`${TopOptionalInfoWidgetStyle.info_banner_text} ${fade ? TopOptionalInfoWidgetStyle.fade_in : TopOptionalInfoWidgetStyle.fade_out}`}
          onMouseEnter={stopRotation} // Stop the rotation and track time left
          onMouseLeave={() => startRotation(timeLeft)} // Resume with the remaining time
        >
          {banners[currentIndex]}
        </div>
        {banners.length > 1 && (
          <Button
            onClick={handleNextClick}
            className={TopOptionalInfoWidgetStyle.slide_buttons}
            lg
            simple
          >
            <img src="/static/img/right_arrow.svg" />
          </Button>
        )}
      </div>
    </section>
  );
};

export default TopOptionalInfoWidget;

/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Text } from "@aws-amplify/ui-react";
import Dropdown from "./Dropdown";
export default function TextLink(props) {
  const {
    label,
    href,
    target,
    withIcon = false,
    overrides: overridesProp,
    ...rest
  } = props;
  const variants = [
    {
      overrides: { Link: {}, Dropdown: {}, "Frame 461": {}, TextLink: {} },
      variantValues: { state: "Default" },
    },
    {
      overrides: {
        Link: { fontWeight: "500", textDecoration: "underline" },
        Dropdown: {},
        "Frame 461": {},
        TextLink: {},
      },
      variantValues: { state: "On Hover" },
    },
    {
      overrides: {
        Link: { color: "rgba(137,148,159,1)" },
        Dropdown: {},
        "Frame 461": {},
        TextLink: {},
      },
      variantValues: { state: "Disabled" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="4px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      display="flex"
      {...getOverrideProps(overrides, "TextLink")}
      {...rest}
    >
      <Flex
        gap="8px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 461")}
      >
        <Text
          fontFamily="Montserrat"
          fontSize="16px"
          fontWeight="400"
          color="rgba(106,51,22,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          as="a"
          href={href}
          target={target}
          children={label}
          {...getOverrideProps(overrides, "Link")}
        ></Text>
        <Dropdown
          width="24px"
          height="24px"
          display={withIcon == true ? "block" : "none"}
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Dropdown")}
        ></Dropdown>
      </Flex>
    </Flex>
  );
}

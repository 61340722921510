import React, { PureComponent } from 'react';
import ProductQuantityStyle from './ProductQuantity.module.scss';
import classNames from 'classnames';

class ProductQuantity extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        className={
          this.props.centered
            ? ProductQuantityStyle.WishlistItem__quantity_centered
            : ProductQuantityStyle.WishlistItem__quantity
        }
      >
        <button
          disabled={this.props.quantity <= 1}
          onClick={() => this.props.updateQuantity(this.props.quantity - 1)}
          className={classNames(
            ProductQuantityStyle.WishlistItem__quantity_minus,
            this.props.quantity === 1
              ? ProductQuantityStyle.WishlistItem__quantity_disabled
              : ProductQuantityStyle.WishlistItem__quantity_enabled
          )}
        >
          -
        </button>
        <p className={ProductQuantityStyle.WishlistItem__quantity_number}>
          {this.props.quantity}
        </p>
        <button
          disabled={this.props.disabledAdd}
          onClick={() => this.props.updateQuantity(this.props.quantity + 1)}
          className={classNames(
            ProductQuantityStyle.WishlistItem__quantity_plus,
            this.props.disabledAdd
              ? ProductQuantityStyle.WishlistItem__quantity_disabled
              : ProductQuantityStyle.WishlistItem__quantity_enabled
          )}
        >
          +
        </button>
      </div>
    );
  }
}
export default ProductQuantity;

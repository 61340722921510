import React from 'react';
import { Link } from 'react-router-dom';
import { Field, Form, reduxForm } from 'redux-form';
import Button from 'material-kit/components/Button';
import LoginRegisterField from './LoginRegisterField';
import LoginRegisterScss from './LoginRegister.scss';
import classNames from 'classnames';

const LoginRegisterHeader = ({ isLogin }) => (
  <div styleName="LoginRegisterScss.LoginRegisterHeader">
    <div class="col-xs-6 col-sm-6">
      <Link to="/">
        <img
          className={classNames(
            LoginRegisterScss.LoginRegisterHeader_logo,
            'HeaderLogo__HeaderLogo__image'
          )}
          src="/static/img/Logo_Rdfood_Final.svg"
        ></img>
      </Link>
    </div>

    {isLogin ? (
      <div
        className="col-xs-6 col-sm-6"
        styleName="LoginRegisterScss.Header_button"
      >
        <p styleName="LoginRegisterScss.LoginRegisterHeader_header_text">
          Don't have an account?
        </p>
        <Button
          styleName="LoginRegisterScss.LoginButton_width_position"
          primary
          component={Link}
          componentProps={{
            to: {
              pathname: '/register',
            },
          }}
        >
          Register
        </Button>
      </div>
    ) : (
      <div
        className="col-xs-6 col-sm-6"
        styleName="LoginRegisterScss.Header_button"
      >
        <p styleName="LoginRegisterScss.LoginRegisterHeader_header_text">
          Already have an account?
        </p>
        <Button
          styleName="LoginRegisterScss.LoginButton_width_position"
          primary
          component={Link}
          componentProps={{
            to: {
              pathname: '/login',
            },
          }}
        >
          Login
        </Button>
      </div>
    )}
  </div>
);

export default LoginRegisterHeader;

/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function LinkedInicon(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="24px"
      height="24px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 24, height: 24 }}
      paths={[
        {
          d: "M2.0001 0L21.9999 0C23.0997 0 24 0.900293 24 2.0001L24 22.0002C24 23.0994 23.0997 24 21.9999 24L2.0001 24C0.900293 24 0 23.0994 0 22.0002L0 2.0001C0 0.900293 0.900293 0 2.0001 0ZM2 22.0001L21.9998 22.0001L21.9998 2L2 2L2 22.0001Z",
          fill: "rgba(106,51,22,1)",
          fillRule: "evenodd",
          style: { transform: "translate(0%, 0%)" },
        },
        {
          d: "M6 9.99922L8.0001 9.99922L8.0001 17.9993L6 17.9993L6 9.99922Z",
          fill: "rgba(106,51,22,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0%, 0%)" },
        },
        {
          d: "M8.49844 7.5C8.49844 8.32843 7.82686 9 6.99844 9C6.17001 9 5.49844 8.32843 5.49844 7.5C5.49844 6.67157 6.17001 6 6.99844 6C7.82686 6 8.49844 6.67157 8.49844 7.5Z",
          fill: "rgba(106,51,22,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0%, 0%)" },
        },
        {
          d: "M14.0002 10C13.2679 10 12.5899 10.2115 12.0001 10.5547L12.0001 10.0831L10 10.0831L10 18.0001L12.0001 18.0001L12.0001 13.9999C12.0001 12.8953 12.8956 12.0001 14.0002 12.0001C15.1048 12.0001 16 12.8953 16 13.9999L16 18.0001L18.0001 18.0001L18.0001 13.9999C18.0001 11.791 16.2091 10 14.0002 10Z",
          fill: "rgba(106,51,22,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0%, 0%)" },
        },
      ]}
      {...getOverrideProps(overrides, "LinkedInicon")}
      {...rest}
    ></Icon>
  );
}

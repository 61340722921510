/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Divider, Flex, SearchField, Text } from "@aws-amplify/ui-react";
import Close from "./Close";
export default function Currencyselector(props) {
  const { onClose, slot, onSearchChange, overrides, ...rest } = props;
  return (
    <Flex
      gap="8px"
      direction="column"
      width="320px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      overflow="hidden"
      position="relative"
      padding="18px 15px 18px 15px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "Currencyselector")}
      {...rest}
    >
      <Flex
        padding="0px 0px 0px 0px"
        width="290px"
        height="146px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        {...getOverrideProps(overrides, "content")}
      >
        <Text
          fontFamily="Caveat Brush"
          fontSize="16px"
          fontWeight="400"
          color="rgba(106,51,22,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="0px"
          left="0px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Countries We Deliver To"
          {...getOverrideProps(overrides, "Label")}
        ></Text>
        <Close
          width="24px"
          height="24px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0px"
          left="264px"
          padding="0px 0px 0px 0px"
          onClick={onClose}
          {...getOverrideProps(overrides, "close")}
        ></Close>
        <Divider
          width="288px"
          height="2px"
          position="absolute"
          top="35px"
          left="0px"
          size="small"
          orientation="horizontal"
          {...getOverrideProps(overrides, "Divider")}
        ></Divider>
        <Text
          fontFamily="Montserrat"
          fontSize="12px"
          fontWeight="400"
          color="rgba(89,84,84,1)"
          lineHeight="18px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="290px"
          height="unset"
          gap="unset"
          alignItems="unset"
          position="absolute"
          top="48px"
          left="0px"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Choose a country or region. This affects the currency you’re billed in, availability of items, price and delivery options."
          {...getOverrideProps(
            overrides,
            "Choose a country or region. This affects the currency you\u2019re billed in, availability of items, price and delivery options."
          )}
        ></Text>
        <SearchField
          width="288px"
          height="unset"
          placeholder="Search"
          position="absolute"
          top="113px"
          left="0px"
          size="small"
          isDisabled={false}
          labelHidden={true}
          variation="default"
          onChange={onSearchChange}
          {...getOverrideProps(overrides, "SearchField")}
        ></SearchField>
      </Flex>
      <Flex
        gap="16px"
        direction="column"
        width="288px"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="8px 8px 8px 8px"
        children={slot}
        {...getOverrideProps(overrides, "slot")}
      ></Flex>
    </Flex>
  );
}

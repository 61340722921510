/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function Instagramicon(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="24px"
      height="24px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 24, height: 24 }}
      paths={[
        {
          d: "M20.0016 5.5C20.0016 6.32843 19.33 7 18.5016 7C17.6731 7 17.0016 6.32843 17.0016 5.5C17.0016 4.67157 17.6731 4 18.5016 4C19.33 4 20.0016 4.67157 20.0016 5.5Z",
          fill: "rgba(106,51,22,1)",
          fillRule: "nonzero",
          style: { transform: "translate(0%, 0%)" },
        },
        {
          d: "M6 12C6 8.68588 8.68559 6 12 6C15.3135 6 18 8.68588 18 12C18 15.3129 15.3135 18.0006 12 18.0006C8.68559 18.0006 6 15.3129 6 12ZM8 11.9999C8 14.2094 9.79098 16.0004 11.9999 16.0004C14.2088 16.0004 15.9998 14.2094 15.9998 11.9999C15.9998 9.79039 14.2088 8 11.9999 8C9.79098 8 8 9.79039 8 11.9999Z",
          fill: "rgba(106,51,22,1)",
          fillRule: "evenodd",
          style: { transform: "translate(0%, 0%)" },
        },
        {
          d: "M2.0001 0L21.9999 0C23.0997 0 24 0.900293 24 2.0001L24 22.0002C24 23.0994 23.0997 24 21.9999 24L2.0001 24C0.900293 24 0 23.0994 0 22.0002L0 2.0001C0 0.900293 0.900293 0 2.0001 0ZM2 22.0001L21.9998 22.0001L21.9998 2L2 2L2 22.0001Z",
          fill: "rgba(106,51,22,1)",
          fillRule: "evenodd",
          style: { transform: "translate(0%, 0%)" },
        },
      ]}
      {...getOverrideProps(overrides, "Instagramicon")}
      {...rest}
    ></Icon>
  );
}

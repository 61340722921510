/*
 * #%L
 * React Site Starter
 * %%
 * Copyright (C) 2009 - 2017 Broadleaf Commerce
 * %%
 * Broadleaf Commerce React Starter
 *
 * Written in 2017 by Broadleaf Commerce info@broadleafcommerce.com
 *
 * To the extent possible under law, the author(s) have dedicated all copyright and related and neighboring rights to this software to the public domain worldwide. This software is distributed without any warranty.
 * You should have received a copy of the CC0 Public Domain Dedication along with this software. If not, see <http://creativecommons.org/publicdomain/zero/1.0/>.
 *
 * Please Note - The scope of CC0 Public Domain Dedication extends to Broadleaf Commerce React Starter demo application alone. Linked libraries (including all Broadleaf Commerce Framework libraries) are subject to their respective licenses, including the requirements and restrictions specified therein.
 * #L%
 */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchOrderHistory } from 'account/actions';
import { getOrders } from 'account/selectors';
import { resolve } from 'core/decorator/reduxResolve';
import { Route, NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Price from 'material-kit/components/Price';
import OrderHistoryDetails from 'account/components/OrderHistoryDetails';
import formatDate from 'date-fns/format';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import ViewOrdersScss from './ViewOrders.scss';
import GlobalScss from '../../../layout/style/common.scss';
import { Link } from 'react-router-dom';

class ViewOrders extends PureComponent {
  static propTypes = {
    orders: PropTypes.array,
    fetchOrderHistory: PropTypes.func,
  };

  componentWillReceiveProps(nextProps) {
    const { authenticationToken, customerToken } = this.props;
    const {
      authenticationToken: newAuthenticationToken,
      customerToken: newCustomerToken,
    } = nextProps;
    if (
      authenticationToken !== newAuthenticationToken ||
      customerToken !== newCustomerToken
    ) {
      nextProps.fetchOrderHistory();
    }
  }

  customFormatDate = (submitDate) => {
    var formattedDate = new Date(submitDate).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });
    return formattedDate.slice(0, 6) + ', ' + formattedDate.slice(6);
  };

  render() {
    const { orders } = this.props;
    return (
      <div>
        <div styleName="GlobalScss.Page_header">
          <div styleName="GlobalScss.Title">My Orders</div>
        </div>
        <div styleName="GlobalScss.Text_below_header">
          {!isEmpty(orders)
            ? `Displaying ${orders.length} of ${orders.length} orders`
            : `You don't have any orders.`}
        </div>
        {orders &&
          orders.map((order) => (
            <div>
              <div styleName="ViewOrdersScss.OrderTotalHeaderText">
                <div
                  className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                  styleName="ViewOrdersScss.OrderStatusParent"
                >
                  <div
                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    styleName="ViewOrdersScss.OrderSummaryHeaderText"
                  >
                    ORDER STATUS:
                  </div>
                  <div styleName="ViewOrdersScss.OrderStatusHeaderText">
                    {order.status}!
                  </div>
                </div>
                <div
                  className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                  styleName="ViewOrdersScss.OrderImagesPreviewParent"
                >
                  {order.orderItems.slice(0, 4).map((item, index) => (
                    <div
                      key={index}
                      styleName="ViewOrdersScss.CartOrderItem__image"
                    >
                      <img
                        src={item.primaryMedia.url}
                        alt={item.primaryMedia.altText}
                        styleName="ViewOrdersScss.OrderImagesPreview"
                      />
                    </div>
                  ))}
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div
                      className="row"
                      styleName="ViewOrdersScss.OrderItemFooterParent"
                    >
                      <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                        <div
                          styleName="ViewOrdersScss.OrderSummaryHeaderText"
                          className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12"
                        >
                          ORDER NUMBER:
                        </div>
                        <div
                          styleName="ViewOrdersScss.OrderSummaryHeaderValue"
                          className="col-xl-8 col-lg-6 col-md-6 col-sm-12 col-xs-12"
                        >
                          {`${order.orderNumber}`}
                        </div>
                      </div>
                      <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                        <div
                          styleName="ViewOrdersScss.OrderSummaryHeaderText"
                          className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
                        >
                          SHIPPED DATE:
                        </div>
                        <div
                          styleName="ViewOrdersScss.OrderSummaryHeaderValue"
                          className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
                        >
                          {`${this.customFormatDate(order.submitDate)}`}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                          styleName="ViewOrdersScss.ViewOrderLinkParentDiv"
                        >
                          <a
                            href={`/account/orders/${order.orderNumber}`}
                            styleName="ViewOrdersScss.ViewOrderLinkText"
                          >
                            View Order
                            <img
                              src={'/static/img/secondaryArrowIcon.svg'}
                              styleName="ViewOrdersScss.ViewOrderLinkImage"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authenticationToken: state.auth.authenticationToken,
    customerToken:
      state.auth.anonymousCustomerToken || state.csr.csrCustomerToken,
    isFetching: state.orderHistory.isFetching,
    orders: getOrders(state),
  };
};

const dispatchResolve = (resolver, props) => {
  if (!props.isFetching) {
    resolver.resolve(props.fetchOrderHistory);
  }
};

export default connect(mapStateToProps, { fetchOrderHistory })(
  resolve(dispatchResolve)(ViewOrders)
);

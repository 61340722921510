/*
 * #%L
 * React Site Starter
 * %%
 * Copyright (C) 2009 - 2017 Broadleaf Commerce
 * %%
 * Broadleaf Commerce React Starter
 *
 * Written in 2017 by Broadleaf Commerce info@broadleafcommerce.com
 *
 * To the extent possible under law, the author(s) have dedicated all copyright and related and neighboring rights to this software to the public domain worldwide. This software is distributed without any warranty.
 * You should have received a copy of the CC0 Public Domain Dedication along with this software. If not, see <http://creativecommons.org/publicdomain/zero/1.0/>.
 *
 * Please Note - The scope of CC0 Public Domain Dedication extends to Broadleaf Commerce React Starter demo application alone. Linked libraries (including all Broadleaf Commerce Framework libraries) are subject to their respective licenses, including the requirements and restrictions specified therein.
 * #L%
 */
import React from 'react';
import { Route, Link } from 'react-router-dom';
import Accordion from 'material-kit/components/Accordion';
import classNames from 'classnames';
import AccountMenuStyle from './AccountMenu.module.scss';
//import '../../../layout/style/common.scss';
import { connect } from 'react-redux';
import { isAnonymous } from 'auth/selectors';
import { getCustomer } from 'account/selectors';
import { handleLogout } from 'auth/actions';
import { invalidateCart } from 'cart/actions';

const AccountLink = ({ children, exact, to, onClick }) => {
  return (
    <Route path={to} exact={exact}>
      {({ match }) => (
        <li className={match ? 'active' : ''}>
          <Link to={to} onClick={onClick}>
            {children}
          </Link>
        </li>
      )}
    </Route>
  );
};

const AccountMenu = ({
  handleLogout,
  invalidateCart,
  collapsed,
  toggle,
  history,
  customer,
}) => (
  <div className="col-sm-4">
    <div
      className={classNames(
        AccountMenuStyle.AccountMenu,
        'card',
        'cart-nav-tabs'
      )}
    >
      <div
        className={classNames(
          AccountMenuStyle.AccountMenu__header,
          'col-sm-12'
        )}
      >
        <div>Hi,</div>
        <br />
        <div className={AccountMenuStyle.AccountHeaderFirstName}>
          {customer.firstName}
        </div>
        <div className={AccountMenuStyle.AccountHeaderLastName}>
          {customer.lastName}
        </div>
        <a
          className={AccountMenuStyle.AccountMenu__collapse}
          onClick={(e) => {
            e.preventDefault();
            toggle();
          }}
        >
          <i className="material-icons">arrow_drop_down_circle</i>
        </a>
      </div>
      <div style={{ display: !collapsed ? 'block' : 'none' }}>
        <ul className="nav nav-pills nav-primary nav-stacked">
          <AccountLink to="/account" exact>
            Account Information
          </AccountLink>
          <AccountLink to="/account/orders">My Orders</AccountLink>
          <AccountLink to="/account/wishlist" exact>
            Manage Wishlist
          </AccountLink>
          <AccountLink to="/account/addresses">Manage Addresses</AccountLink>
          <AccountLink to="/account/password" exact>
            Change Password
          </AccountLink>
          <AccountLink
            to=""
            exact
            onClick={() => {
              Promise.all([handleLogout(), invalidateCart()]).then(() =>
                history.push('/')
              );
            }}
          >
            Sign out
          </AccountLink>
          {/*<AccountLink to="/account/payments">Manage Payments</AccountLink>*/}
        </ul>
      </div>
    </div>
  </div>
);

const AccordionAccountMenu = ({
  history,
  customer,
  handleLogout,
  invalidateCart,
}) => (
  <Accordion collapsed={false}>
    <AccountMenu
      handleLogout={handleLogout}
      invalidateCart={invalidateCart}
      history={history}
      customer={customer}
    />
  </Accordion>
);

const mapStateToProps = (state) => {
  return {
    ...state,
    customer: getCustomer(state),
  };
};

export default connect(mapStateToProps, { handleLogout, invalidateCart })(
  AccordionAccountMenu
);

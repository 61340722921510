import React, { useEffect, useState } from 'react';
import NewsletterSubscribeStyle from './NewsletterSubscribe.module.scss';
import Button from 'material-kit/components/Button';
import Checkbox from 'material-kit/components/Checkbox';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import GlobalScss from '../../../../layout/style/common.scss';
import { connect } from 'react-redux';
import request from 'core/util/superagent';
import Toaster from '../../../../layout/components/Toaster/Toaster';
import { validateEmail } from 'layout/util/emailUtils';

const NewsletterSubscribe = ({
  kindOfFoodRef,
  authenticationToken,
  customerToken,
}) => {
  const [email, setEmail] = useState('');

  const scrollToKindOfFood = () => {
    if (kindOfFoodRef.current) {
      kindOfFoodRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSubscribe = () => {
    if (!validateEmail(email)) {
      setToasterMessage('Please enter your email in valid format');
      setShowToaster(true);
      return;
    }
    return new Promise((resolve, reject) => {
      const authHeader = authenticationToken
        ? { Authorization: authenticationToken }
        : {};
      const customerHeader = customerToken
        ? { 'X-Customer-Token': customerToken }
        : {};
      request
        .post('/api/customer/newsletter/subscribe')
        .query({ email: email })
        .set(authHeader)
        .set(customerHeader)
        .end((err, response) => {
          if (err) {
            reject(err);
          }
          if (response) {
            resolve(response);
          }
        });
    })
      .then((response) => {
        setToasterMessage('You have successfully subscribed to our newsletter');
        setShowToaster(true);
      })
      .catch((error) => {
        setToasterMessage('Subscription failed');
        setShowToaster(true);
      });
  };

  const [showToaster, setShowToaster] = useState(false);
  const [toasterMessage, setToasterMessage] = useState('');

  return (
    <div>
      <div className={NewsletterSubscribeStyle.TopSubscribeDiv}>
        <div className={GlobalScss.GridWrapper}>
          <div className={NewsletterSubscribeStyle.TopSubscribeContainer}>
            <div
              className={classNames(
                NewsletterSubscribeStyle.TopSubscribeLeftText,
                'col-xs-12 col-md-6 col-lg-8'
              )}
            >
              <h2 className={GlobalScss.Mt0}>
                Discover the Authentic Taste of India with Hand-made,
                preservative-free products.
              </h2>
              <div className={NewsletterSubscribeStyle.CloudsDiv}>
                <img src="/static/img/clouds.svg" />
              </div>
              <Button primary onClick={scrollToKindOfFood} round>
                Explore Our Range
              </Button>
            </div>
            <div
              className={classNames(
                NewsletterSubscribeStyle.SubscribeImagesDiv,
                'col-xs-12 col-md-6 col-lg-4'
              )}
            >
              <img
                src="static/img/home/ABOUT_US/Interruption-CTA-image-Muruku-lemon-picklex1.webp"
                srcSet="
                static/img/home/ABOUT_US/Interruption-CTA-image-Muruku-lemon-picklex1.webp 480w,
                static/img/home/ABOUT_US/Interruption-CTA-image-Muruku-lemon-picklex2.webp 800w
              "
                sizes="
                (max-width: 600px) 480px,
                800px
              "
                alt="Left Img"
                className={NewsletterSubscribeStyle.HomeRightImg}
              />
            </div>
          </div>
        </div>
        {/* <div
        className={classNames(
          NewsletterSubscribeStyle.HelperDiv,
          'col-xs-12 col-md-12 col-lg-12'
        )}
      ></div> */}
        <div
          className={classNames(
            NewsletterSubscribeStyle.NewsletterDiv,
            'col-xs-12 col-md-12 col-lg-12'
          )}
        >
          <div
            className={classNames(
              NewsletterSubscribeStyle.NewsletterSubDiv,
              GlobalScss.GridWrapper
            )}
          >
            <div className="col-xs-12 col-md-5">
              <div className={NewsletterSubscribeStyle.NewsletterTitle}>
                Newsletter Sign Up
              </div>
              <div className={NewsletterSubscribeStyle.NewsletterText}>
                Stay Updated with Reddot Food. Join our community and get
                exclusive offers and delicious recipes.
              </div>
            </div>
            <div className="col-xs-12 col-md-offset-1 col-md-6">
              <div className={NewsletterSubscribeStyle.EmailTitle}>Email</div>
              <div
                className={classNames(
                  NewsletterSubscribeStyle.SearchInputParent,
                  'col-xs-11 input-group'
                )}
              >
                <input
                  //className='form-control'
                  className={NewsletterSubscribeStyle.SearchInput}
                  placeholder="Your Email address"
                  autoComplete="off"
                  type="text"
                  name="search"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <span
                  className={classNames(
                    NewsletterSubscribeStyle.SearchIconDiv,
                    'input-group-btn'
                  )}
                >
                  <Button
                    primary
                    simple
                    round
                    type="button"
                    onClick={handleSubscribe}
                  >
                    Subscribe
                  </Button>
                </span>
              </div>
              <div className={NewsletterSubscribeStyle.CheckBoxDiv}>
                <Checkbox id="newsletter">
                  <label
                    htmlFor="newsletter"
                    className={NewsletterSubscribeStyle.CheckBoxLabel}
                  >
                    I agree to receive related communication from Reddot Food
                  </label>
                </Checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showToaster && (
        <Toaster
          message={toasterMessage}
          onClose={() => setShowToaster(false)}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authenticationToken: state.auth.authenticationToken,
    customerToken:
      state.auth.anonymousCustomerToken || state.csr.csrCustomerToken,
  };
};

export default connect(mapStateToProps)(NewsletterSubscribe);

const countries = [
  { name: 'Austria', abbreviation: 'AT' },
  { name: 'Belgium', abbreviation: 'BE' },
  { name: 'Bulgaria', abbreviation: 'BG' },
  { name: 'Croatia', abbreviation: 'HR' },
  { name: 'Cyprus', abbreviation: 'CY' },
  { name: 'Czech Republic', abbreviation: 'CZ' },
  { name: 'Denmark', abbreviation: 'DK' },
  { name: 'Estonia', abbreviation: 'EE' },
  { name: 'Finland', abbreviation: 'FI' },
  { name: 'France', abbreviation: 'FR' },
  { name: 'Germany', abbreviation: 'DE' },
  { name: 'Greece', abbreviation: 'GR' },
  { name: 'Hungary', abbreviation: 'HU' },
  { name: 'Ireland', abbreviation: 'IE' },
  { name: 'Italy', abbreviation: 'IT' },
  { name: 'Latvia', abbreviation: 'LV' },
  { name: 'Lithuania', abbreviation: 'LT' },
  { name: 'Luxembourg', abbreviation: 'LU' },
  { name: 'Malta', abbreviation: 'MT' },
  { name: 'Netherlands', abbreviation: 'NL' },
  { name: 'Poland', abbreviation: 'PL' },
  { name: 'Portugal', abbreviation: 'PT' },
  { name: 'Romania', abbreviation: 'RO' },
  { name: 'Slovakia (Slovak Republic)', abbreviation: 'SK' },
  { name: 'Slovenia', abbreviation: 'SI' },
  { name: 'Spain', abbreviation: 'ES' },
  { name: 'Sweden', abbreviation: 'SE' },
  { name: 'Local IP', abbreviation: 'RD' },
];

const baseUrl = 'https://flagcdn.com/';

const euCountries = countries.map((country) => ({
  ...country,
  // img: baseUrl + country.abbreviation.toLowerCase() + '.svg'
}));

const euCountriesJSON = JSON.stringify(euCountries, null, 2);

export default euCountriesJSON;
/*
const updatedCountries = euCountries.map(country => ({
    ...country,
    img: baseUrl + country.abbreviation.toLowerCase + '.svg'
}));

console.log(JSON.stringify(updatedCountries, null, 2));
*/

/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import {
  Flex,
  Image,
  SelectField,
  StepperField,
  Text,
} from "@aws-amplify/ui-react";
import CustomBadges from "./CustomBadges";
export default function MiniCartListItem(props) {
  const {
    image,
    trashCanIconSrc,
    priceLabel,
    title,
    onDeleteClick,
    amount,
    productUrl,
    pricePer,
    savedLabel,
    isSale,
    isNew,
    onQuantityDecrease,
    onQuantityIncrease,
    price,
    overrides: overridesProp,
    ...rest
  } = props;
  const variants = [
    {
      overrides: {
        Image50352616: {},
        CustomBadges50352867: {},
        CustomBadges50352878: {},
        Optionaltags: {},
        label: {},
        Text50352624: {},
        Text50352625: {},
        "Frame 491": {},
        "Frame 482": {},
        SelectField: {},
        StepperField: {},
        "Frame 483": {},
        "Frame 484": {},
        leftcontent: {},
        Rate: {},
        Image50352632: {},
        Button: {},
        "Frame 486": {},
        MiniCartListItem: {},
      },
      variantValues: { type: "Default" },
    },
    {
      overrides: {
        Image50352616: {},
        CustomBadges50352867: { type: "Bundle" },
        CustomBadges50352878: {},
        Optionaltags: {},
        label: {},
        Text50352624: {},
        Text50352625: {},
        "Frame 491": {},
        "Frame 482": {},
        SelectField: {},
        StepperField: {},
        "Frame 483": {},
        "Frame 484": {},
        leftcontent: {},
        Rate: {},
        Image50352632: {},
        Button: {},
        "Frame 486": {},
        MiniCartListItem: { width: "597px" },
      },
      variantValues: { type: "Bundle" },
    },
    {
      overrides: {
        Image50352616: {},
        CustomBadges50352867: { type: "Bestseller" },
        CustomBadges50352878: {},
        Optionaltags: {},
        label: {},
        Text50352624: {},
        Text50352625: {},
        "Frame 491": {},
        "Frame 482": {},
        SelectField: {},
        StepperField: {},
        "Frame 483": {},
        "Frame 484": {},
        leftcontent: {},
        Rate: {},
        Image50352632: {},
        Button: {},
        "Frame 486": {},
        MiniCartListItem: { width: "597px" },
      },
      variantValues: { type: "Best Seller" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="9px"
      direction="row"
      width="596px"
      height="181px"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
      border="1px SOLID rgba(137,148,159,1)"
      padding="7.5px 7.5px 7.5px 7.5px"
      backgroundColor="rgba(252,242,233,1)"
      display="flex"
      {...getOverrideProps(overrides, "MiniCartListItem")}
      {...rest}
    >
      <Flex
        gap="14px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "leftcontent")}
      >
        <Image
          width="154px"
          height="123px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src={image}
          {...getOverrideProps(overrides, "Image50352616")}
        ></Image>
        <Flex
          gap="10px"
          direction="column"
          width="334px"
          height="177px"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Frame 484")}
        >
          <Flex
            gap="8px"
            direction="column"
            width="225px"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 482")}
          >
            <Flex
              gap="8px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Optionaltags")}
            >
              <CustomBadges
                display={isNew == true ? "block" : "none"}
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                position="relative"
                borderRadius="32px"
                type="New"
                {...getOverrideProps(overrides, "CustomBadges50352867")}
              ></CustomBadges>
              <CustomBadges
                display={isSale == true ? "block" : "none"}
                gap="10px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                position="relative"
                borderRadius="32px"
                padding="6px 8px 6px 8px"
                backgroundColor="rgba(191,64,64,1)"
                type="Sale"
                {...getOverrideProps(overrides, "CustomBadges50352878")}
              ></CustomBadges>
            </Flex>
            <Text
              fontFamily="Montserrat"
              fontSize="16px"
              fontWeight="700"
              color="rgba(106,51,22,1)"
              lineHeight="20px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="23px"
              gap="unset"
              alignItems="unset"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              as="a"
              href={productUrl}
              children={title}
              {...getOverrideProps(overrides, "label")}
            ></Text>
            <Flex
              gap="8px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              display="flex"
              {...getOverrideProps(overrides, "Frame 491")}
            >
              <Text
                fontFamily="Montserrat"
                fontSize="16px"
                fontWeight="400"
                color="rgba(77,77,77,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={pricePer}
                {...getOverrideProps(overrides, "Text50352624")}
              ></Text>
              <Text
                fontFamily="Montserrat"
                fontSize="14px"
                fontWeight="700"
                color="rgba(63,125,74,1)"
                lineHeight="21px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children={savedLabel}
                {...getOverrideProps(overrides, "Text50352625")}
              ></Text>
            </Flex>
          </Flex>
          <Flex
            gap="16px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            display="flex"
            {...getOverrideProps(overrides, "Frame 483")}
          >
            <SelectField
              width="160px"
              height="unset"
              label="Options"
              display="none"
              shrink="0"
              placeholder="Placeholder"
              size="small"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              {...getOverrideProps(overrides, "SelectField")}
            ></SelectField>
            <StepperField
              width="160px"
              height="unset"
              label="Quantity"
              shrink="0"
              size="small"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              onIncrease={onQuantityIncrease}
              onDecrease={onQuantityDecrease}
              defaultValue={amount}
              min={0}
              {...getOverrideProps(overrides, "StepperField")}
            ></StepperField>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="76px"
        direction="column"
        width="69px"
        height="132px"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 486")}
      >
        <Text
          fontFamily="Montserrat"
          fontSize="16px"
          fontWeight="700"
          color="rgba(106,51,22,1)"
          lineHeight="20px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="23px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={priceLabel}
          {...getOverrideProps(overrides, "Rate")}
        ></Text>
        <Flex
          gap="8px"
          direction="row"
          width="24px"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Button")}
        >
          <Image
            width="16px"
            height="18px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src={trashCanIconSrc}
            onClick={onDeleteClick}
            {...getOverrideProps(overrides, "Image50352632")}
          ></Image>
        </Flex>
      </Flex>
    </Flex>
  );
}

import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import isEmpty from 'lodash/isEmpty';
import ProductImagePickerStyles from './ProductImagePicker.module.scss';
import classNames from 'classnames';

const ProductImagePicker = ({ className, media = [] }) => {
  const [isWideScreen, setIsWideScreen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    // Dinamički import CSS datoteke samo na klijentskoj strani
    import('react-responsive-carousel/lib/styles/carousel.min.css');
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleChange = (index) => {
    setSelectedIndex(index);
  };

  return (
    <div
      className={classNames(className, ProductImagePickerStyles.CarouselParent)}
    >
      <Carousel
        className={ProductImagePickerStyles.CarouselContent}
        showArrows={false}
        showIndicators={!isWideScreen}
        showStatus={false}
        showThumbs={isWideScreen}
        infiniteLoop={true}
        selectedItem={selectedIndex}
        onChange={handleChange}
        thumbWidth={110}
        renderThumbs={(thumbs) => {
          if (Array.isArray(thumbs) && thumbs.length > 0) {
            return thumbs.map((thumb, index) => (
              <div
                key={index}
                className={classNames(
                  ProductImagePickerStyles.ProductViewThumb,
                  index === selectedIndex
                    ? ProductImagePickerStyles.ProductViewSelectedThumb
                    : {}
                )}
              >
                {thumb}
              </div>
            ));
          }
          return null; // In case thumbs is not a string or is empty, return null
        }}
      >
        {media && !isEmpty(media) ? (
          media.map((item, index) => (
            <img
              key={index}
              alt={item.altText}
              src={item.url}
              className={ProductImagePickerStyles.ProductViewImage}
            />
          ))
        ) : (
          <img src="/static/img/noPhoto.png" alt="No photo available" />
        )}
      </Carousel>
    </div>
  );
};

export default ProductImagePicker;

import React, { PureComponent, useRef } from 'react';
import CheckoutAsGuestForm from './CheckoutAsGuestForm';
import Button from 'material-kit/components/Button';
import './LoginRegister.scss';
import { Helmet } from 'react-helmet';
import { Field, Form, reduxForm } from 'redux-form';
import LoginRegisterField from './LoginRegisterField';
import ForgotPasswordForm from './ForgotPasswordForm';
import { handleForgotPassword } from '../../actions/authActions';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { Messages } from 'primereact/messages';

class ForgotPassword extends PureComponent {
  static defaultProps = {
    isCheckoutLogin: false,
  };

  state = {
    redirectToReferrer: false,
    forgotPasswordMessage: '',
  };

  _handleForgotPasswordSubmit = (form) => {
    const { username } = form;
    return this.props.handleForgotPassword(username).then((action) => {
      if (action.payload.error) {
        throw new SubmissionError({
          _error:
            'The e-mail address entered do not match our records. Please try again',
        });
      } else {
        this.setState(
          {
            forgotPasswordMessage:
              'Please check your email for a new password!',
          },
          () => {
            setTimeout(() => {
              this.setState({ forgotPasswordMessage: '' });
            }, 5000); // 5 sekundi
          }
        );
      }
    });
  };

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } };
    const { redirectToReferrer } = this.state;

    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }

    const { isCheckoutLogin } = this.props;

    return (
      <div className="container">
        <div id="account" xmlns="http://www.w3.org/1999/xhtml">
          <title>Forgot Password</title>

          <div className="section">
            <div className="col-sm-6 col-sm-offset-3">
              <div>
                <div className="form-group label-floating is-empty">
                  <ForgotPasswordForm
                    onSubmit={this._handleForgotPasswordSubmit}
                  />
                  <div className="forgot-password-message">
                    {this.state.forgotPasswordMessage && (
                      <p style={{ textAlign: 'center', color: 'red' }}>
                        {this.state.forgotPasswordMessage}
                      </p>
                    )}
                  </div>
                  <span className="material-input"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { handleForgotPassword })(ForgotPassword);

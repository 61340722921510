/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function Facebookicon(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="24px"
      height="24px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 24, height: 24 }}
      paths={[
        {
          d: "M2.0001 0L21.9999 0C23.0997 0 24 0.900293 24 2.0001L24 22.0002C24 23.0994 23.0997 24 21.9999 24L2.0001 24C0.900293 24 0 23.0994 0 22.0002L0 2.0001C0 0.900293 0.900293 0 2.0001 0ZM14 22.0001L21.9998 22.0001L21.9998 2L2 2L2 22.0001L11.9999 22.0001L11.9999 13.9997L9.9998 13.9997L9.9998 11.9999L11.9999 11.9999L11.9999 9.9998C11.9999 7.2377 14.2391 5 17 4.99971L18.9998 4.99971L18.9998 6.9998L17 6.9998C15.3437 6.9998 14 8.3426 14 9.9998L14 11.9999L18.9998 11.9999L18.9998 13.9997L14 13.9997L14 22.0001Z",
          fill: "rgba(106,51,22,1)",
          fillRule: "evenodd",
          style: { transform: "translate(0%, 0%)" },
        },
      ]}
      {...getOverrideProps(overrides, "Facebookicon")}
      {...rest}
    ></Icon>
  );
}

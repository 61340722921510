/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex } from "@aws-amplify/ui-react";
export default function NavLinks(props) {
  const { navLinks, overrides, ...rest } = props;
  return (
    <Flex
      gap="32px"
      direction="row"
      width="585px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(253,252,247,1)"
      children={navLinks}
      {...getOverrideProps(overrides, "NavLinks")}
      {...rest}
    ></Flex>
  );
}
